import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Nav, Card } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import Tab from 'react-bootstrap/Tab';
import { gsap } from 'gsap'
import logo from '../../../assets/regal_images/regal_logo.png'
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as HomeActions from "../../../actions/HomeActions";
import * as MovieAction from "../../../actions/MovieAction";
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { Avatar, Box, CircularProgress, Grid, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import Share from '../common/Share';
// import videotest from '../../../assets/video/sample-video.mp4'
import VideoJS from '../../../player/player'
import videojs from 'video.js';
// import * as videoButtons from 'videojs-seek-buttons'
import { ROUTES } from '../../../constant/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// require("@silvermine/videojs-quality-selector")(videojs);
import trending1 from '../../../assets/images/trending/01.jpg'
import plus from '../../../assets/regal_images/plus.png'
import check from '../../../assets/regal_images/check.png'
import { toast } from 'react-toastify';
import AlertMessage from '../common/AlertMessage';
import RazorpayButton from '../../../razorpay/razorpayButton'
import playIcon from '../../../assets/regal_images/play-button.png'
import Modal from '@mui/material/Modal';
import rent_icon from '../../../assets/regal_images/Rent (1).png'
import axios from 'axios';
import unmute from '../../../assets/regal_images/volume.png'
import silent from '../../../assets/regal_images/mute.png'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};

const styleforAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 600,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
}

// install Swiper modules
SwiperCore.use([Navigation]);


const AddMovie = () => {
    const playerRef = React.useRef(null);
    const [toggler1, setToggler1] = useState(false);
    const parameter = useParams();
    const [movieData, setMovieData] = useState("")
    const dispatch = useDispatch()
    const { getMovieDetails } = bindActionCreators(HomeActions, dispatch)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory()
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [save, setSave] = useState(false)
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const [popupOpen, setPopupOpen] = useState(false)
    const handlePopupClose = () => {
        setPopupOpen(false);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))

    const { addIntoWatchList } = bindActionCreators(MovieAction, dispatch)
    const { addToLikedContent } = MovieAction
    const { removeFromWatchlist, setCompleteHours } = MovieAction
    const [ip, setIP] = useState("")
    const movie_data = useSelector((state) => state?.home?.movieDetails)
    console.log("movie_data", movie_data)
    const [hoursAlert, setHoursAlert] = useState(false)
    const [completedHour, setCompletedHour] = useState(false)

    const handleClose = () => {
        setAnchorEl(null);
    };
    const [hoursLeft, setHoursLeft] = useState(calculateHoursLeft());

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip)
        console.log(res)

    };
    useEffect(() => {
        getData();
    }, []);


    useEffect(() => {
        (async () => {

            document.body.scrollTop = document.documentElement.scrollTop = 0;

            if (parameter?.slug) {
                const res = await axios.get("https://api.ipify.org/?format=json");

                let userObj = {
                    user_id: user_id,
                    profile_id: profile_id,
                    ip_address: res.data.ip

                }
                const resData = await getMovieDetails(parameter?.slug, userObj)
                setMovieData(resData)
                console.log(resData)
            }

            const intervalId = setInterval(() => {
                setHoursLeft(calculateHoursLeft());
            }, 1000);

            // Clear the interval when the component is unmounted
            return () => clearInterval(intervalId);

        })();
    }, [save, parameter?.slug])


    function calculateHoursLeft() {
        const now = new Date();
        const nextDay = new Date(now);
        nextDay.setDate(now.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);

        const timeDifference = nextDay - now;
        const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));

        return hoursLeft;
    }

    const videoJsOptions = {
        autoplay: true,
        loop: true,
        controls: false,
        responsive: true,
        fluid: true,
        muted: true,
        poster: `${IMAGE_ENDPOINT + movieData?.Data?.movie_poster}`,

        plugins: {
            // seekButtons: {
            //     forward: 10,
            //     back: 10
            // }
        },
        volumeControl: false,
        playbackRates: [0.5, 1, 1.5, 2],
        userActions: { hotkeys: true },
        controlBar: {

            qualitySelector: true,
            pictureInPictureToggle: false,

        },

        languages: [{}],
        sources: [
            {
                src: `${movieData?.Data?.free_preview}`,
                // src: "https://tygon.s3.ap-south-1.amazonaws.com/m3u8/Bhoori/1080p.m3u8",
                type: 'video/mp4',
                label: "1080p",
                selected: true,
            }
        ]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.controlBar.addChild('button', {
            controlText: 'Title',
            className: 'vjs-visible-text',
            position: 2,
        });


        // player.on('playing',()=>{
        //     player.muted(false)
        // })
        // player.addChild("volumePanel").removeClass("vjs-hover")

        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };


    const handlePlayNow = () => {
        if (!isLoggedIn) {
            setPopupOpen(true)
            sessionStorage.setItem("previospage", movieData?.Data?.slug)
        }
        else {
            if (movieData?.max_movie_watch_complete == "true" && movieData?.Data?.movie_access == "SVOD") {
                setPopupOpen(true)

            }
            else {
                if (movieData?.Data?.movie_access == "TVOD" && movieData?.get_status == "false") {
                    setHoursAlert(true)
                }
                else if (movieData?.Data?.movie_access == "TVOD" && movieData?.get_status == "completed") {
                    setCompletedHour(true)
                }
                else {

                    if (movieData?.Data?.movie_is_for18Plus == "Yes") {
                        if (!popupOpen)
                            setPopupOpen(true)
                        else
                            history.push(ROUTES.video, { movie: movieData, resume: movieData?.last_watching_time })
                    }
                    else {
                        history.push(ROUTES.video, { movie: movieData, resume: movieData?.last_watching_time })
                    }
                }

            }
        }


    }

    const handleHoursContinue = async () => {
        const send_status = await setCompleteHours({
            user: user_id,
            movie: movieData?.Data?.id
        })
        if (send_status?.statuscode == 200) {
            history.push(ROUTES.video, { movie: movieData, resume: movieData?.last_watching_time })
        }

    }

    const handleRedirect = (slug) => {
        history.push(`${ROUTES.moviedetails}/${slug}`)
        setSave(!save)
        setMovieData("")
    }
    // const handleForceRefresh = () => {
    //     setSave(!save)

    // }

    const handleTrailer = () => {
        let userObj = {
            movie_name: movieData?.Data?.movie_name,
            Trailer: movieData?.Data?.movie_trailer
        }

        history.push(ROUTES.trailer, { data: userObj })
    }

    const AddWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_ID: profile_id,
                movie: id
            }
            const res = await addIntoWatchList(userObj)
            if (res?.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
            else {
                toast.error("Something went wrong.")
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    };

    const RemoveWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_ID: profile_id,
                movie: id,
                series_id: ""
            }
            const res = await removeFromWatchlist(userObj)
            if (res.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    }
    const releaseDate = new Date(movieData?.Data?.release_date + "T" + movieData?.Data?.publish_time + "Z")

    const handleHoursDialogueClose = () => {
        setHoursAlert(false);
        setCompletedHour(false)
    };

    const likedContent = async () => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                movie_id: movieData?.Data?.id,
                movie_like: movieData?.is_movie_like == true ? 0 : 1,
                movie_dislike: movieData?.is_movie_like ? 1 : 0,
                profile_id: profile_id
            }
            const resData = await addToLikedContent(userObj)
            if (resData?.statuscode == 200) {
                setSave(!save)
            }
        }
        else {
            toast.info("Please login to continue.")
        }


    }

    return (
        <>
            {/* <AlertMessage /> */}

            <Modal
                open={popupOpen}
                onClose={handlePopupClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {!isLoggedIn ? <Box sx={style}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Please login to continue.
                        </Typography>
                        <button onClick={() => history.push("/auth/login")} className={"btn btn-hover iq-button mt-3"} style={{ display: "block", margin: "0 auto" }}>
                            Login
                        </button>
                    </div>
                </Box> :
                    (movieData?.max_movie_watch_complete == "true" ?
                        <Box sx={styleforAlert}>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src={logo} width={"100px"} />
                            </div>
                            <hr />

                            <div className="login-popup" >
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                                    You have already seen this film maximum number of times. To watch it again, purchase a Diamond Plan.
                                </Typography>
                                <div className='mt-3' style={{ display: "flex", justifyContent: "center" }}>

                                    <button onClick={handlePopupClose} className={"btn btn-hover iq-button ml-2"} >
                                        Okay
                                    </button>
                                </div>
                            </div>
                        </Box> : <Box sx={style}>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src={logo} width={"100px"} />
                            </div>
                            <hr />

                            <div className="login-popup" >
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                                    Please confirm you are 18+.
                                </Typography>
                                <div className='mt-3' style={{ display: "flex", justifyContent: "end" }}>
                                    <button onClick={handlePlayNow} className={"btn btn-hover iq-button"}>
                                        Yes
                                    </button>
                                    <button onClick={handlePopupClose} className={"btn btn-hover iq-button ml-2"} >
                                        No
                                    </button>
                                </div>
                            </div>
                        </Box>)
                }
            </Modal>
            {
                movie_data && movieData ?
                    <div>


                        <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
                            <div
                                onClick={(e) => {
                                    playerRef.current.muted(!playerRef.current.muted())
                                    document.getElementById("mute-button").className = !playerRef.current.muted() ? "ri-volume-up-fill" : "ri-volume-mute-fill"
                                    // document.getElementById("mute-button1").className = !playerRef.current.muted() ? "display-none" : "fa fa-times"

                                }}
                                style={{
                                    position: "absolute",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    right: "4vw",
                                    top: "20vh",
                                    zIndex: "10",
                                    fontSize: "20px"
                                }}>
                                <div style={{ background: "#00000047", padding: "10px", borderRadius: "100px", border: "1px solid #ffffff", display: "flex", alignItems: "center" }}>
                                    <i className="ri-volume-mute-fill" id='mute-button' aria-hidden="true"></i>

                                </div>
                            </div>
                            <div
                                id="home-slider"
                                className="slick-bg-1 m-0 p-0">
                                {/* <video autoPlay muted loop id="myVideo" className='slide'> */}
                                {/* <source src={videotest} type="video/mp4"/> */}
                                <div className='bg-video'>

                                    {movieData?.Data?.free_preview && <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />}

                                </div>

                                <SwiperSlide className="slick-bg-2 slick-bg-3">
                                    <Container fluid className="position-relative h-100">
                                        <div className="slider-inner h-100">
                                            <Row className="align-items-center iq-ltr-direction h-100 ">
                                                <Col xl="6" lg="12" md="12" >
                                                    {
                                                        movieData?.Data?.movie_logo ?
                                                            <Link to="#">
                                                                <div className="channel-logo pb-4" data-iq-delay="0.5">
                                                                    <img src={IMAGE_ENDPOINT + movieData?.Data?.movie_logo} style={{ maxHeight: "200px" }} className="c-logo" alt={movieData?.Data?.movie_name} />
                                                                </div>
                                                            </Link>
                                                            :
                                                            <h2>{movieData?.Data.movie_name}</h2>

                                                    }


                                                    <div className="d-flex flex-wrap align-items-center">
                                                        <p data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8" dangerouslySetInnerHTML={{ __html: movieData?.Data?.movie_description?.length <= 250 ? movieData?.Data?.movie_description : movieData?.Data?.movie_description?.substring(0, 250) + "..." }}>
                                                        </p>
                                                    </div>

                                                    <div className="trending-list" data-wp_object-in="fadeInUp" data-delay-in="1.2">
                                                        <p>
                                                            <b>Language:</b> <span>{movieData?.Data?.movie_language}</span>
                                                            <br />
                                                            <b>Genres:</b> {movieData?.Data?.movie_genre?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data.genre_title}</span>))}
                                                            <br />
                                                            <b>Starring:</b> {movieData?.Cast?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data.cast_name}</span>))}
                                                        </p>



                                                    </div>
                                                    <div className="d-flex align-items-start r-mb-23 mt-2" data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">

                                                        {movieData?.Data?.publish_type == "Later" && new Date() < releaseDate ?
                                                            <p className='upcoming-p' >RELEASING ON <br /><span >{releaseDate.toLocaleDateString('en-GB', { timeZone: 'Europe/London' })}&nbsp;|&nbsp;{releaseDate.toLocaleTimeString('en-IN', { timeZone: 'Europe/London', hour: 'numeric', minute: "2-digit", hour12: true })}</span></p>
                                                            :
                                                            <div>
                                                                {movieData?.Data?.movie_access == "SVOD" &&
                                                                    (movieData?.is_subscription_expiry ?
                                                                        <Button className="btn btn-hover iq-button" onClick={() => history.push("/price-plan")}>
                                                                            <i className="fa fa-play mr-2" aria-hidden="true"></i>Subscribe Now
                                                                        </Button> :

                                                                        <Button className="btn btn-hover iq-button" onClick={handlePlayNow}>
                                                                            <i className="fa fa-play mr-2" aria-hidden="true"></i>{movieData?.last_watching_time != "" ? "Continue watch" : "Play"}
                                                                        </Button>)
                                                                }
                                                                {movieData?.Data?.movie_access == "FREE" &&
                                                                    <Button className="btn btn-hover iq-button" onClick={handlePlayNow}>
                                                                        <i className="fa fa-play mr-2" aria-hidden="true"></i>{movieData?.last_watching_time != "" ? "Continue watch" : "Play"}
                                                                    </Button>
                                                                }

                                                                {movieData?.Data?.movie_access == "TVOD" &&
                                                                    (movieData?.is_rental_movie_expiry ?
                                                                        <>
                                                                            <button className={"btn btn-hover iq-button"} onClick={() => history.push("/price-plan", { transactionType: "TVOD", available_for: movieData?.Data?.available_for_days, movieName: movieData?.Data?.movie_name, movie: movieData?.Data?.id, categoryType: "Movie", price: movieData?.Data?.rental_price_with_ad, adfreePrice: movieData?.Data?.rental_price, ads: movieData?.advertise, slug: movieData?.Data?.slug })}>Rent Now : ${Number(movieData?.advertise?.length ? movieData?.Data?.rental_price_with_ad : movieData?.Data?.rental_price).toFixed(2)}</button>
                                                                            {/* <RazorpayButton className={"btn btn-hover iq-button"} title={"Rent Now : $"+Number(movieData?.Data?.rental_price).toFixed(2)} forceRefresh={setSave} transactionType={"TVOD"} movie={movieData?.Data?.id} categoryType={"Movie"} price={movieData?.Data?.rental_price}/> */}
                                                                        </>
                                                                        :
                                                                        <div >
                                                                            <Button className="btn btn-hover iq-button" onClick={handlePlayNow}>
                                                                                <i className="fa fa-play mr-2" aria-hidden="true"></i>{movieData?.last_watching_time != "" ? "Continue watch" : "Play"}

                                                                            </Button>
                                                                            <span style={{ fontSize: "12px", display: "flex", justifyContent: "center", marginTop: ".3rem", textTransform: "none" }}>{movieData?.days_total != 0 ? movieData?.days_total : ""} {movieData?.days_total > 1 ? "days" : movieData?.days_total == 0 ? `${hoursLeft} hours` : "day"} remaining</span>
                                                                        </div>

                                                                    )}
                                                            </div>}
                                                        {/* <Link to="/watchlist">
                                                <i className="fa fa-plus icon-border ml-2"></i>
                                            </Link>

                                            <div to="/movie-details" onClick={handleClick}>
                                                <i className="fa fa-share icon-border ml-2"></i>
                                            </div> */}

                                                        {
                                                            movieData?.isWatchlist ?
                                                                <div className='ml-3' onClick={() => RemoveWatchList(movieData?.Data?.id)} style={{ cursor: "pointer" }}>
                                                                    <i className="fa fa-check icon-border" aria-hidden="true"></i>
                                                                    {/* <img src={check} style={{ height: "30px", cursor: "pointer" }} alt='add Watchlist' /> */}

                                                                </div>
                                                                :
                                                                <div className='ml-3' onClick={() => AddWatchList(movieData?.Data?.id)} style={{ cursor: "pointer" }}>
                                                                    <i className="fa fa-plus icon-border"></i>
                                                                    {/* <img src={plus} style={{ height: "30px", cursor: "pointer" }} alt='add Watchlist' /> */}
                                                                </div>
                                                        }
                                                        {/* 
                                            <div onClick={handleClick}>
                                                <i className="fa fa-share-alt share-icon ml-3" aria-hidden="true"></i>
                                            </div> */}

                                                        <div onClick={handleClick} style={{ cursor: "pointer" }}>
                                                            <i className="fa fa-share icon-border ml-2"></i>
                                                        </div>

                                                        <div onClick={likedContent} style={{ cursor: "pointer" }}>
                                                            <i className="fa fa-heart icon-border ml-2" style={{ color: `${movieData?.is_movie_like ? "#c22034" : ""}` }}></i>
                                                        </div>

                                                        <Popover
                                                            id={id}
                                                            className='mt-2'
                                                            open={open}
                                                            anchorEl={anchorEl}
                                                            onClose={handleClose}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}

                                                        >
                                                            <Typography sx={{ p: 2 }}>
                                                                <Share sharedURL={window.location.href} />
                                                            </Typography>
                                                        </Popover>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>
                                    </Container>
                                </SwiperSlide>

                                {/* </video> */}
                            </div>
                        </section>

                        <div>
                            <Swiper onSwiper={setThumbsSwiper}
                                slidesPerView={1}
                                freeMode={true}
                                watchSlidesProgress={true}
                                id="trending-slider"
                                className="mt-3  list-inline p-0 m-0  d-flex align-items-center iq-rtl-direction">
                                <SwiperSlide as="li">
                                    <div className="tranding-block position-relative"
                                        style={{ backgroundImage: `url(${trending1})` }}>
                                        <Tab.Container defaultActiveKey="trending-data1" className="trending-custom-tab">
                                            <div className="tab-title-info position-relative iq-ltr-direction">
                                                <Nav as="ul" variant="pills" className="trending-pills nav-pills d-flex justify-content-center align-items-center text-center iq-ltr-direction">
                                                    <Nav.Item as="li" className="nav-item">
                                                        <Nav.Link href="" eventKey="trending-data1">Trailer</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item as="li" className="nav-item">
                                                        <Nav.Link href="" eventKey="trending-data2">Cast</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item as="li" className="nav-item">
                                                        <Nav.Link href="" eventKey="trending-data3">Description</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                            <Tab.Content className="trending-content">
                                                <Tab.Pane eventKey="trending-data1" className="overlay-tab show fade">
                                                    <section id="parallex" className="">
                                                        <Container>
                                                            <Row className="align-items-center justify-content-center h-100 parallaxt-details">
                                                                <Col lg="6">
                                                                    {/* <div className="parallax-img">
                                                            <Link to="/movie-details">
                                                                <img src={`${IMAGE_ENDPOINT + movieData?.Data?.movie_poster}`} className="img-fluid w-100 trailer-img" alt="bailey" />
                                                            </Link>
                                                        </div> */}
                                                                    <div className="position-relative">
                                                                        <div className="img-box">
                                                                            <img src={`${IMAGE_ENDPOINT + movieData?.Data?.movie_poster}`} className="img-fluid w-100 trailer-img" alt="bailey" />
                                                                        </div>
                                                                        <div className="block-icon-trailer">
                                                                            <div onClick={handleTrailer} role="button" className="">
                                                                                <img src={playIcon} alt='play' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="6" className="r-mb-23">
                                                                    <div className="text-left">

                                                                        <div className="parallax-ratting d-flex align-items-center mt-3 mb-3">
                                                                            <h6>{movieData?.Data?.movie_name}</h6>
                                                                        </div>
                                                                        <div className="movie-time d-flex align-items-center mb-3 iq-ltr-direction">
                                                                            {/* <div className="badge badge-secondary mr-3">13+</div> */}
                                                                            <h6 className="text-white">Duration : {movieData?.Data?.movie_duration}</h6>
                                                                        </div>
                                                                        <p style={{ fontSize: "13px" }} dangerouslySetInnerHTML={{ __html: movieData?.Data?.movie_description }}></p>
                                                                        {/* <div className="parallax-buttons">
                                                                <Link to="/show-details" className="btn btn-hover iq-button">
                                                                    <i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now
                                                                </Link>
                                                            </div> */}
                                                                        <p>
                                                                            <b>Language:</b> <span>{movieData?.Data?.movie_language}</span>
                                                                        </p>
                                                                        <p>
                                                                            <b>Genres:</b> {movieData?.Data?.movie_genre?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data.genre_title}</span>))}
                                                                        </p>
                                                                        <p>
                                                                            <b>Starring:</b> {movieData?.Cast?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data.cast_name}</span>))}
                                                                        </p>



                                                                    </div>
                                                                </Col>

                                                            </Row>
                                                        </Container>
                                                    </section>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="trending-data2" className="overlay-tab  fade show " id="trending-episode1">
                                                    <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                        <Row className='mt-3'>
                                                            <Grid container spacing={2} >
                                                                {
                                                                    movieData?.Cast?.map((data, index) => (
                                                                        <Grid item xl={2} lg={2} md={3} sm={4} xs={6} key={index} className='grid-center'>

                                                                            <img src={`${IMAGE_ENDPOINT}/${data?.cast_image}`} className="avtar-img" alt={data?.movie_name} />
                                                                            <h6 className='mt-2' style={{ color: "#b9213a", textAlign: "center" }} >{data?.cast_type}</h6>
                                                                            <h6 style={{ color: "white", textAlign: "center" }} className='mt-1'>{data?.cast_name}</h6>

                                                                        </Grid>
                                                                    ))
                                                                }

                                                            </Grid>
                                                        </Row>

                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="trending-data3" className="overlay-tab fade show">
                                                    <Container>
                                                        <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">

                                                            <div>
                                                                <h5>Content Advisory</h5>
                                                                <ul className='mt-2'>
                                                                    {
                                                                        movieData?.Data?.content_advisory?.map((data, index) => (
                                                                            <li key={index}>{data?.content_advisory}</li>
                                                                        ))
                                                                    }
                                                                </ul>

                                                                <h5>Audio Languages</h5>
                                                                <ul className='mt-2'>
                                                                    {
                                                                        movieData?.audio?.length ?
                                                                            movieData?.audio?.map((data, index) => (
                                                                                <li key={index}>{data?.audio_language}</li>
                                                                            ))
                                                                            :
                                                                            <p>No Multiple Audio Files Added.</p>
                                                                    }
                                                                </ul>
                                                                <h5>Subtitles</h5>
                                                                <ul className='mt-2'>

                                                                    {
                                                                        movieData?.subtitle?.length ?
                                                                            movieData?.subtitle?.map((data, index) => (
                                                                                <li key={index}>{data?.subtitle_language}</li>
                                                                            ))
                                                                            :
                                                                            <p> No Multiple Subtitles Files Added.</p>
                                                                    }
                                                                </ul>
                                                                <h5>Description</h5>
                                                                <p style={{ fontSize: "17px", marginTop: "1.2rem" }} dangerouslySetInnerHTML={{ __html: movieData?.Data?.movie_description }}></p>                                                </div>
                                                        </div>
                                                    </Container>
                                                </Tab.Pane>

                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>


                        {/* Related movie data */}

                        <div className='main-content'>
                            <section id="iq-favorites">
                                <Container fluid>
                                    <Row>
                                        <Col sm="12" className="overflow-hidden">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="main-title">Related Movies</h4>
                                                {/* <Link className="iq-view-all" to="/movie-category">View All</Link> */}
                                            </div>
                                            <div id="favorites-contens">
                                                {/* <div id="prev" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                    <div id="next" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                                <Swiper
                                                    navigation={{
                                                        prevEl: '#prev',
                                                        nextEl: '#next'
                                                    }}
                                                    breakpoints={{
                                                        320: { slidesPerView: 2 },
                                                        450: { slidesPerView: 3 },
                                                        638: { slidesPerView: 3 },
                                                        810: { slidesPerView: 4 },
                                                        // 991: { slidesPerView: 4 },
                                                        1048: { slidesPerView: 5 },
                                                        1300: { slidesPerView: 6 }
                                                        // 1400: { slidesPerView: 4 },
                                                        // 1800: { slidesPerView: 5 },
                                                    }}
                                                    loop={false}
                                                    slidesPerView={6}
                                                    spaceBetween={0}
                                                    as="ul"
                                                    className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">

                                                    {
                                                        movieData?.Related_Movie?.map((data, index) => (
                                                            <SwiperSlide as="li" key={index}  >
                                                                <div onClick={() => handleRedirect(data?.slug)}>
                                                                    <img src={`${IMAGE_ENDPOINT}/${data?.movie_thumbnail}`} className="thumb-img" style={{ cursor: "pointer" }} alt={data?.movie_name} />
                                                                    {data?.movie_access == "TVOD" && <div> <div className="block-icon-tvod">
                                                                        <img src={rent_icon} width={"75px"} />
                                                                        {/* <span> Rental : $ {Number(data?.rental_price).toFixed(2)}</span> */}
                                                                    </div>
                                                                    </div>}
                                                                    <Card className='hover-class' style={{ cursor: "pointer" }}>
                                                                        <Card.Img variant="top" src={`${IMAGE_ENDPOINT}/${data?.movie_poster}`} />
                                                                        <Card.Body style={{ marginTop: "-1rem" }}>
                                                                            <span style={{ color: "#ffffff", fontSize: "13px" }}>{data?.movie_name}
                                                                            </span><br />
                                                                            <div style={{ marginTop: "-0.3rem" }}>
                                                                                <span style={{ color: "#ffffff", fontSize: "10px" }}>Duration: {data?.movie_duration}
                                                                                </span>
                                                                                <p style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: data?.movie_description?.length <= 60 ? data?.movie_description : data?.movie_description?.substring(0, 60) + "..." }}></p>
                                                                                <div style={{ display: "flex", background: "#ffffff", color: "#000000", fontWeight: "bolder", borderRadius: "0.5rem", }}>
                                                                                    <div style={{ fontSize: "13px", margin: "0 auto", padding: "5px" }}><i className="fa fa-info" aria-hidden="true"></i>  Details</div>
                                                                                </div>
                                                                            </div>

                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))
                                                    }


                                                </Swiper>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </div>
                    </div>
                    :
                    <div style={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center" }}>
                        <CircularProgress />
                    </div>
            }
            <Modal
                open={hoursAlert}
                onClose={(handleHoursDialogueClose)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Once you Start watching Movie, It must be finished within 72 hours or it will automatically be removed.
                        </Typography>
                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1.5rem" }}>

                            <button onClick={handleHoursContinue} className={"btn btn-hover iq-button"}>
                                Watch Now
                            </button>
                            <button onClick={handleHoursDialogueClose} className={"btn btn-hover ml-3 iq-button"}>
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={completedHour}
                onClose={(handleHoursDialogueClose)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            You have exceeded 72 hours of movie watching limitation , please rent now to continue                         </Typography>
                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1.5rem" }}>
                            <button onClick={(handleHoursDialogueClose)} className={"btn btn-hover ml-3 iq-button"}>
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>

        </>
    )
}
export default AddMovie;