import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { ROUTES } from '../constant/routes';
// import { payment } from '../actions/PaymentActions';
import * as PaymentAction from '../actions/PaymentActions'
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import { CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';
import payment_success from "../assets/images/payment_success.png"

const Checkout = () => {
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory()
    const stripe1 = require('stripe')('sk_test_LfW4Fw7PFIPuDd5m1k1aZSgw00pvixLEJH');
    const [paymentDetails, setPaymentDetails] = useState("")
    const [success, setSuccess] = useState(false)
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const dispatch = useDispatch()
    const { payment } = bindActionCreators(PaymentAction, dispatch)
    const [loader, setLoader] = useState(false)

    const handleSubmit = async (event) => {
        setLoader(true)
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        try {
            const result = await stripe.confirmPayment({
                elements,
                redirect: 'if_required'
            });


            if (result.error) {
                setLoader(false)
                console.log(result.error.message);
                toast.error(result.error.message)
            } else {
                setLoader(false)
                toast.success("Payment success")
                try {
                    const paymentIntent = await stripe1?.paymentIntents?.retrieve(result?.paymentIntent?.id);
                    console.log('Metadata:', paymentIntent);
                    setPaymentDetails(paymentIntent)
                    const today = new Date();
                    const dd = String(today.getDate()).padStart(2, '0');
                    const mm = String(today.getMonth() + 1).padStart(2, '0');
                    const yyyy = today.getFullYear();
                    const res = await axios.get("https://api.ipify.org/?format=json");
                    let userObj = {
                        ad_free: paymentIntent?.metadata?.adfree,
                        category_type: paymentIntent?.metadata?.category_type,
                        date: `${dd}/${mm}/${yyyy}`,
                        device_id: "",
                        device_type: "Web",
                        email: paymentIntent?.metadata?.email,
                        gateway: "stripe",
                        ip_address: res.data.ip,
                        location: "",
                        mobile_no: paymentIntent?.metadata?.mobile_no,
                        movie: Number(paymentIntent?.metadata?.movie) || null,
                        name: paymentIntent?.metadata?.name,
                        payment_amount: ((result?.paymentIntent?.amount) / 100),
                        payment_id: result?.paymentIntent?.id,
                        payment_method: result?.paymentIntent?.payment_method,
                        plan: Number(paymentIntent?.metadata?.plan),
                        promocode: paymentIntent?.metadata?.promocode,
                        series: Number(paymentIntent?.metadata?.series) || null,
                        transaction_type: paymentIntent?.metadata?.transaction_type,
                        user: user_id
                    }
                    // dispatch(payment(userObj));
                    const payment_api = await payment(userObj)
                    console.log("success", payment_api)
                    setSuccess(true)
                    if (payment_api) {
                        setTimeout(() => {
                            setSuccess(false)
                            if (paymentIntent?.metadata?.movie) {
                                history.push(`${ROUTES.moviedetails}/${paymentIntent?.metadata?.slug}`)
                            }
                            else if (paymentIntent?.metadata?.series) {
                                history.push(`${ROUTES.seriesDetails}/${paymentIntent?.metadata?.slug}`)
                            }
                            else {
                                history.push('/')
                            }

                        }, 3000);
                    }


                } catch (error) {
                    console.error('Error retrieving payment intent:', error);
                }
                console.log("stripe", result, result?.paymentIntent?.id)

            }
        }
        catch (error) {
            console.error('Error confirming payment:', error);

        }

    };

    return (
        <form onSubmit={handleSubmit}>
            <Container>
                <div className='payment-stripe-form' style={{ margin: "auto", textAlign: "center", padding: "30px", marginTop: "6rem" }}>
                    <PaymentElement />
                    {
                        loader ?
                            <CircularProgress />
                            :
                            <>
                                <button className={"btn btn-hover iq-button text_small mt-4"} disabled={!stripe}>Submit</button>
                                {
                                    success ? <div className="Success-Popup-stripe">
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img src={payment_success} width={"250px"} />
                                        </div>
                                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                                            Congragulations!!
                                        </Typography>
                                        <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }}>
                                            Payment Succeccful<br />
                                            Payment ID : {paymentDetails?.id}
                                        </Typography>
                                    </div>
                                        :
                                        <></>
                                }

                            </>
                    }
                </div>
            </Container>
        </form>
    )
};

export default Checkout;