import { Grid } from '@mui/material'
import React from 'react'
import { Container } from 'react-bootstrap'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

export default function PaymentSuccess() {
    const location = useLocation()
    console.log(location?.state?.data)

    return (
        // <div className='mt-5 pt-5'>{JSON.stringify(location?.state?.data)}</div>
        <>
            <section className="sign-in-page" style={{ marginTop: "5rem" }}>
                <Container>
                    <div>
                        <h4>Payment Details</h4>
                    </div>
                    <div className='mt-4'>
                        Name : {location?.state?.data?.metadata?.name} <br/>
                        Email : {location?.state?.data?.metadata?.email}<br/>
                        Mobile : {location?.state?.data?.metadata?.mobile_no}<br/>
                        Amount : $ {(location?.state?.data?.amount)/100}.00<br/>
                        Payment ID : {location?.state?.data?.id}
                    </div>

                </Container>

            </section>
        </>
    )
}
