import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as MoviesAction from '../../../../actions/MovieAction'
import * as HomeAction from '../../../../actions/HomeActions'

import { Card, Container, Dropdown, DropdownButton } from 'react-bootstrap'
import { Grid } from '@mui/material'
import { ROUTES } from '../../../../constant/routes'
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList'
import { Link } from 'react-router-dom'
import aboutus1 from '../../../../assets/images/about-us/01.jpg'
import { Row, Col, Breadcrumb, Form, Button } from 'react-bootstrap'
import empty from '../../../../assets/regal_images/empty-box (1).png'
import rent_icon from '../../../../assets/regal_images/Rent (1).png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function MovieByLanguage() {

    const location = useLocation()
    const dispatch = useDispatch()
    const { getAllMoviesByLanguage } = bindActionCreators(MoviesAction, dispatch)
    const { getAllHomeData } = bindActionCreators(HomeAction, dispatch)
    const genres = useSelector((state) => state?.home?.homeData?.data?.Genre)

    const [movies, setMovies] = useState([])
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const [homedata, setHomeData] = useState([])
    const history = useHistory()
    useEffect(() => {

        (async () => {
            if (location?.state?.id) {
                const formData = new FormData()
                formData.append("id", location?.state?.id)
                const resData = await getAllMoviesByLanguage(formData)
                setMovies(resData)
                let userObj = {
                    user_id: user_id ? user_id : "null",
                    profile_id: profile_id ? profile_id : "null"
                }
                const Homedata = await getAllHomeData(userObj)
                setHomeData(Homedata?.data?.Genre)
            }
        })();

    }, [])

    const handleGenre = (data) => {
        history.push(ROUTES.moviesByGenre, { id: data?.id })
    }

    return (
        <>

            <section className="m-profile setting-wrapper">
                <Container>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <h4 className="main-title mb-5 mt-5">Filtered by "{movies?.language_name}"</h4>

                        </div>

                        <div className='dropdown-btn-genre drpdwn-colour'>
                            <DropdownButton id="dropdown-item-button" title={'Filter by Genre'}>
                                <div style={{ maxHeight: '200px', overflowY: 'auto'}} className='remove-scroll'>
                                    {homedata?.data?.map((data, index) => (
                                        <Dropdown.Item key={index} style={{cursor:"pointer",color:"white"}} as="button" onClick={() => handleGenre(data)}>
                                            {data.genre_title}
                                        </Dropdown.Item>
                                    ))
                                    }
                                </div>
                            </DropdownButton>
                        </div>

                    </div>
                    <Grid container spacing={2}>
                        {
                            movies?.data?.length ?
                                movies?.data?.map((data, index) => (
                                    <Grid item lg={2} xl={2} md={2} sm={4} xs={6} key={index} className='grid-img-cls'>
                                        <Link to={`${ROUTES.moviedetails}/${data?.slug}`}>
                                            <img src={`${IMAGE_ENDPOINT + data?.movie_thumbnail}`} className="thumb-img-filter" alt={data?.movie_name} />
                                            {data?.movie_access == "TVOD" && <div className="block-icon-tvod-list">
                                                <img src={rent_icon} width={"75px"} />
                                            </div>}
                                            <Card className='hover-class'>
                                                <Card.Img variant="top" src={`${IMAGE_ENDPOINT}/${data?.movie_poster}`} />
                                                <Card.Body style={{ marginTop: "-1rem" }}>
                                                    <span style={{ color: "#ffffff", fontSize: "13px" }}>{data?.movie_name}
                                                    </span><br />
                                                    <div style={{ marginTop: "-0.3rem" }}>
                                                        <span style={{ color: "#ffffff", fontSize: "10px" }}>Duration: {data?.movie_duration}
                                                        </span>
                                                        <p style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: data?.movie_description?.length <= 60 ? data?.movie_description : data?.movie_description?.substring(0, 60) + "..." }}></p>
                                                        <div style={{ display: "flex", background: "#ffffff", color: "#000000", fontWeight: "bolder", borderRadius: "0.5rem", }}>
                                                            <div style={{ fontSize: "13px", margin: "0 auto", padding: "5px" }}><i className="fa fa-info" aria-hidden="true"></i>  Details</div>
                                                        </div>
                                                    </div>

                                                </Card.Body>
                                            </Card>
                                        </Link>
                                    </Grid>
                                ))
                                :
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img src={empty} alt='empty' style={{ height: "60px" }} />
                                        <p className='pt-4'> Sorry, No result found.</p>
                                    </div>
                                </Grid>
                        }

                    </Grid>
                </Container>
            </section>
        </>
    )
}
