import { ADD_CONTINUE_WATCHING, ADD_CONTINUE_WATCHING_ERROR, ADD_MOVIE_IN_WATCHLIST, ADD_MOVIE_IN_WATCHLIST_ERROR, GET_ALL_MOVIES, GET_ALL_MOVIES_BY_GENRE, GET_ALL_MOVIES_BY_GENRE_ERROR, GET_ALL_MOVIES_BY_LANGUAGE, GET_ALL_MOVIES_BY_LANGUAGE_ERROR, GET_ALL_MOVIES_ERROR, GET_ALL_SERIES, GET_ALL_SERIES_ERROR, GET_CONTINUE_WATCHING, GET_CONTINUE_WATCHING_ERROR, GET_MOVIE_DETAILS, GET_MOVIE_DETAILS_ERROR, GET_RECOMMENDED_MOVIES, GET_RECOMMENDED_MOVIES_ERROR, GET_SLIDER_DATA, GET_SLIDER_DATA_ERROR, GET_USER_WATCHLIST, GET_USER_WATCHLIST_ERROR } from "../../Actiontype/ActionType"
const initstate = { recommended: null, addcontinueWatching: null, continueWatching: null, series: null, addWatchlist: null, watchlist: null, movies: null, movieByLanguage: null, movieByGenre: null }

const MovieStore = (state = initstate, action) => {

    const { type, payload } = action

    switch (type) {

        case GET_USER_WATCHLIST: {
            return {
                ...state,
                watchlist: payload
            }
        }
        case GET_USER_WATCHLIST_ERROR: {

            return {
                ...state,
                watchlist: null
            }
        }

        case GET_ALL_MOVIES: {
            return {
                ...state,
                movies: payload
            }
        }
        case GET_ALL_MOVIES_ERROR: {

            return {
                ...state,
                movies: null
            }
        }

        case GET_ALL_SERIES: {
            return {
                ...state,
                series: payload
            }
        }
        case GET_ALL_SERIES_ERROR: {

            return {
                ...state,
                series: null
            }
        }

        case GET_CONTINUE_WATCHING: {
            return {
                ...state,
                continueWatching: payload
            }
        }
        case GET_CONTINUE_WATCHING_ERROR: {

            return {
                ...state,
                continueWatching: null
            }
        }

        case GET_ALL_MOVIES_BY_LANGUAGE: {
            return {
                ...state,
                movieByLanguage: payload
            }
        }
        case GET_ALL_MOVIES_BY_LANGUAGE_ERROR: {

            return {
                ...state,
                movieByLanguage: null
            }
        }
        case GET_ALL_MOVIES_BY_GENRE: {
            console.log("in store ", payload)

            return {
                ...state,
                movieByGenre: payload
            }
        }
        case GET_ALL_MOVIES_BY_GENRE_ERROR: {

            return {
                ...state,
                movieByGenre: null
            }
        }

        case ADD_MOVIE_IN_WATCHLIST: {
            return {
                ...state,
                addWatchlist: payload
            }
        }
        case ADD_MOVIE_IN_WATCHLIST_ERROR: {

            return {
                ...state,
                addWatchlist: null
            }
        }

        case GET_RECOMMENDED_MOVIES: {
            return {
                ...state,
                recommended: payload
            }
        }
        case GET_RECOMMENDED_MOVIES_ERROR: {

            return {
                ...state,
                recommended: null
            }
        }

        case ADD_CONTINUE_WATCHING: {
            console.log("in store ", payload)
            return {
                ...state,
                addcontinueWatching: payload
            }
        }
        case ADD_CONTINUE_WATCHING_ERROR: {

            return {
                ...state,
                addcontinueWatching: null
            }
        }

        default:
            return state
    }

}


export default MovieStore