import React, { useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styles from "./form.module.css";
import "./form.css"
import { useState } from "react";
import axios from "axios";

import OtpInput from 'react-otp-input';
import FormControl from "@mui/material/FormControl";
import InputGroup from 'react-bootstrap/InputGroup';

import { login, forgot_password_verify_otp, resend_otp } from "../../../../actions/HomeActions"
import { useDispatch, useSelector } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Col, Form, Row } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import AlertMessage from '../../common/AlertMessage';
import { FORGOT_PASSWORD_MESSAGE } from "../../../../Actiontype/ActionType";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import subscriptionBG from '../../../../assets/images/subscriptionBG.jpg'
import LoadingGIF from '../../../../assets/regal_images/regal_logo.png'



const SignInForm = () => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const [form, setForm] = useState({})
    const [seconds, setSeconds] = useState(0);
    const resendOTPDuration = 60

    useEffect(() => {
        // Exit early if countdown is finished
        if (seconds <= 0) {
            return;
        }

        // Set up the timer
        const timer = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        // Clean up the timer
        return () => clearInterval(timer);
    }, [seconds]);
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60)
            .toString()
            .padStart(2, '0');
        const seconds = (timeInSeconds % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        console.log(res.data);
        setForm({ ...form, ip_address: res.data.ip });
    };
    useEffect(() => {
        getData();
    }, []);
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(form)

        dispatch(login(form, history))
        setSeconds(resendOTPDuration)
    };
    //   const logoutMessage = location.state?.message

    const [open, setOpen] = useState(false)
    //   useMemo(() => {
    //     if (logoutMessage != "" && logoutMessage != undefined)
    //       setOpen(true);
    //   }, [logoutMessage])
    const [passwordType, setPasswordType] = useState("Password")
    const message = useSelector((state) => state.home.login_message);
    const verification_message = useSelector((state) => state.home.forgot_password_message);

    useMemo(() => {
        if (verification_message?.statuscode == 200) {
            if (form?.password) { dispatch(login(form, history)) }
        }


    }, [verification_message])
    useEffect(() => {
        if (location?.state?.showPasswordUpdateMessage)
            toast.success(location?.state?.showPasswordUpdateMessage)
        dispatch({ type: FORGOT_PASSWORD_MESSAGE, payload: null })
    }, [location?.state?.showPasswordUpdateMessage])
    const handleClose = () => {


        setOpen(false);
    };
    const handle_forgot_password_verify_otp_Submit = (event) => {
        event.preventDefault();


        dispatch(forgot_password_verify_otp({ ...form }));

    }
    return (
        <>

            <div className="signin">
                <AlertMessage />
                <div className={styles.authenticationBox} >
                    <Box
                        component="main"
                        sx={{
                            maxWidth: "390px",

                            // mr: "10%",
                            padding: "00px 20px 20px 20px",

                            borderRadius: "00px 0px 10px 10px",
                            // boxShadow: "#e25d65 -2px -2px 5px 1px,#e25d65 -2px 2px 5px 1px,#e25d65 2px -2px 5px 1px, #9d58a3 2px 2px 5px 1px, #9d58a3 -2px 2px 5px 1px, #9d58a3 2px -2px 5px 1px",
                            boxShadow: "#fff -2px -2px 2px 1px,#fff -2px 2px 2px 1px,#fff 2px -2px 2px 1px, #fff 2px 2px 2px 1px, #fff -2px 2px 2px 1px, #fff 2px -2px 2px 1px",

                            // boxShadow: "var(--gradientColorLighter2) 0px 6px 5px -2px, var(--gradientColorLighter1) 0px 3px 7px -3px",

                        }}
                    >
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <Box>






                                <Box component="form" sx={{ backgroundColor: "rgb(225,225,225,0)", borderRadius: "10px", p: "20px" }} onSubmit={(e) => message?.statuscode != 404 ? handleSubmit(e) : handle_forgot_password_verify_otp_Submit(e)}>

                                    <Typography as="h1" fontSize="28px" className={styles.h1} fontWeight="700" mb="5px">
                                        <p style={{ textAlign: "center", fontSize: "30px" }}> Sign In{" "}</p>

                                    </Typography>
                                    <Box
                                        sx={{
                                            padding: "20px 20px",

                                            borderRadius: "10px",
                                            mb: "20px",
                                        }}
                                        className="bg-black"
                                    >
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item xs={12}>

                                                <Form.Group>
                                                    <Form.Label style={{ display: "flex", color: "white" }}>Mobile No / Email </Form.Label>
                                                    <Form.Control type="text" className="form-control mb-0" onChange={(event) => (setForm({ ...form, username: event.target.value }))} id="exampleInputEmail1" placeholder="Mobile No / Email" autoComplete="off" required />
                                                </Form.Group>
                                            </Grid>

                                            <Grid item xs={12}>


                                                <Form.Group >
                                                    <Form.Label style={{ display: "flex", color: "white" }}>Password</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="password"
                                                            onChange={(event) => (setForm({ ...form, password: event.target.value }))} className="promocode-input" id="password" placeholder="Password" required />
                                                        <Button variant="outline-secondary" onClick={(e) => {
                                                            let temp = document.getElementById("password")
                                                            if (temp.type == "password") {
                                                                temp.type = "text"
                                                                document.getElementById("password_icon").className = "fa fa-eye"
                                                            }
                                                            else {
                                                                temp.type = "password"
                                                                document.getElementById("password_icon").className = "fa fa-eye-slash"

                                                            }
                                                        }} className='promocode-button'>
                                                            <i className="fa fa-eye-slash" id="password_icon" style={{ color: "#ffffff" }}></i>
                                                        </Button>
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter the password.
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Grid>


                                            <div style={{ color: "red", fontSize: "13px" }}>
                                                {(message?.statuscode != 200 && message?.message)}

                                            </div>

                                            {message?.statuscode == 404 && <Grid item xs={12}>


                                                <Form.Group >
                                                    <Form.Label style={{ color: "white" }}>OTP</Form.Label>
                                                    <FormControl fullWidth >
                                                        <OtpInput
                                                            containerStyle={{ display: "flex", justifyContent: "space-evenly" }}
                                                            value={form?.OTP}
                                                            onChange={(a) => (setForm({ ...form, OTP: a }))}
                                                            numInputs={4}
                                                            inputStyle={{ width: "3em", height: "3em" }}
                                                            inputType='text'
                                                            renderSeparator={<span> &nbsp;-&nbsp; </span>}
                                                            renderInput={(props) => <input {...props} />}
                                                        />
                                                        {/* { (form?.OTP==""||form?.OTP==undefined)&& <Form.Control.Feedback style={{display:"flex",justifyContent:"center"}} type="invalid">
                                                      Please enter the OTP you have received on Email id/Mobile No
            </Form.Control.Feedback>} */}
                                                    </FormControl>
                                                </Form.Group>
                                                {seconds > 0 ? <p style={{ textAlign: "center" }}>Resend OTP in {formatTime(seconds)}</p> :
                                                    <p style={{ textAlign: "center", cursor: "pointer" }}
                                                        onClick={(event) => {
                                                            dispatch(resend_otp(form))
                                                            setSeconds(resendOTPDuration)
                                                        }}
                                                    >Resend OTP</p>}
                                            </Grid>}



                                        </Grid>
                                    </Box>

                                    {message?.statuscode !== 404 && <Grid container alignItems="center" spacing={2}>

                                        <Grid item xs={6} sm={6}>
                                            <FormControlLabel
                                                className={styles.textSize}

                                                control={
                                                    <Checkbox value="true" className={styles.textSize} onChange={(event) => (setForm({ ...form, remember: event.target.checked }))} name="remember_me" style={{ fontSize: "14px !important", color: "white" }} />
                                                }
                                                label={<span className={styles.textSize} style={{ fontSize: "14px !important", color: "white" }}>Remember me.</span>}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={6} textAlign="end">
                                            <Link
                                                to="/forgot_password"
                                                className={"primaryColor text-decoration-none " + styles.textSize}

                                            >
                                                <span style={{ fontSize: "14px !important", color: "white" }}>Forgot password?</span>
                                            </Link>
                                        </Grid>
                                        <Grid item xs={6} sm={12} >
                                            <Link
                                                to="/sign-up"
                                                className={"primaryColor text-decoration-none " + styles.textSize}

                                            >
                                                <span style={{ color: "white" }}>  Don't have an account? <span style={{ color: "#c22034", fontSize: "22px" }}><b>  Sign up</b></span></span>
                                            </Link>
                                        </Grid>
                                    </Grid>}


                                    <Button
                                        type="submit"
                                        className="btn btn-hover iq-button"
                                        sx={{
                                            display: "block",
                                            width: "100%",
                                            color: "white",
                                            marginTop: "1rem"
                                        }}
                                    >
                                        Sign In
                                    </Button>
                                    <Grid item xs={6} sm={12} sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }} >
                                        <Link
                                            to="/"
                                            className={"primaryColor text-decoration-none " + styles.textSize}

                                        >
                                            <span style={{ color: "white" }}>  Back to home</span>
                                        </Link>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Box>

                </div>
                <p className={styles.authFooter}>Designed and Developed by  <a href="#" style={{ color: "#e35466", marginLeft: "5px", marginRight: "5px" }} target="_blank">Imagine OTT.</a> All rights reserved 2023
                </p>

                {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}


      >
        <Alert severity="info" variant="filled" color="success">{logoutMessage}</Alert>
      </Snackbar> */}
            </div>
        </>
    );
};

export default SignInForm;
