import React, { useState, useEffect, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import "./form.css"

import { useDispatch } from 'react-redux';
import { user_profile_list } from "../../../../actions/HomeActions"
import { useSelector } from 'react-redux';
import addProfile from "../../../../assets/images/addProfile.png"
import bg from "../../../../assets/images/Bg.jpg"
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTES } from '../../../../constant/routes';

const ProfileSelect = (props) => {
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const previospage = sessionStorage.getItem("previospage")
    const dispatch = useDispatch()
    let history = useHistory()
    const location = useLocation()
    const profiles = useSelector((state) => state.home.profiles);

    useEffect(() => { dispatch(user_profile_list({ id: user_id })) }, [])


    console.log(profiles)



    return (
        <div style={{
            // backgroundImage: "url(" + bg + ")",
            backgroundSize: "cover"
        }}>
            <div style={{
                display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", padding: "0 10%", flexDirection: "column",
                background: "rgb(0,0,0,.5)",

            }}>
                <h1 style={{ textAlign: "center", marginBottom: ".5rem", fontWeight: "unset", fontSize: "3.5vw", color: "white" }}>Who's watching</h1>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {profiles?.data?.map((ele, index) => <div key={index} style={{ margin: "2rem", cursor: "pointer" }} >
                        <img
                            src={IMAGE_ENDPOINT + ele?.avatar?.avatarImage}
                            onClick={() => JSON.parse(ele?.profile_secure) && ele?.id !== profile_id ?
                                history.push("/auth/profile_pin", { profile: { ...ele, type: "profile_select" } })
                                : (sessionStorage.setItem("profile", JSON.stringify(ele)), history.push(previospage ? ROUTES.moviedetails + "/" + previospage : "/"))}
                            style={{ maxWidth: "150px" }}
                            className="rounded img-fluid d-block mx-auto mb-3"
                            alt="user" />
                        <p style={{ textAlign: "center" }}>{ele?.profileName || "No Name"}</p>
                    </div>)}
                    {profiles?.data?.length < 4 && <div style={{ margin: "2rem", cursor: "pointer" }} >
                        <img src={addProfile} onClick={() => (history.push("/profile"))} style={{ maxWidth: "150px" }} className="rounded img-fluid d-block mx-auto mb-3" alt="user" />
                        <p style={{ textAlign: "center" }}>&nbsp;</p>
                    </div>}

                </div>
                <button onClick={() => (history.push("/auth/profile_manage"))} className="btn btn-hover iq-button">Manage Profiles</button>
                {location?.state?.back ?
                    <Link to={ROUTES.my_account} style={{ cursor: "pointer", paddingTop: "1rem" }}><i className="fa fa-arrow-left" aria-hidden="true"></i>  Back</Link>
                    :
                    <></>
                }

            </div>
        </div>
    )
}

export default ProfileSelect