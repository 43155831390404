import { Switch, Route } from 'react-router-dom'
import Layout1 from '../layouts/backend/layout1'
import BlankLayout from '../layouts/blanklayout';
import VideoFile from '../player/VideoFile';
import { ROUTES } from '../constant/routes';
import StreamFile from '../player/StreamFile';
import TrailerFile from '../player/TrailerFile';
import EpisodeFile from '../player/EpisodeFile';

const LayoutsRoute = props => {
    return (
        <Switch>
            <Route path="/auth" component={BlankLayout} />
            <Route path={ROUTES.video} component={VideoFile} />
            <Route path={ROUTES.stream} component={StreamFile} />
            <Route path={ROUTES.trailer} component={TrailerFile} />
            <Route path={ROUTES.episode} component={EpisodeFile} />

            <Route path="/" component={Layout1} />
        </Switch>
    )
}

export default LayoutsRoute