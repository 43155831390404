import { GET_MOVIE_DETAILS, GET_MOVIE_DETAILS_ERROR, GET_SLIDER_DATA, GET_SLIDER_DATA_ERROR,AVATARS,REGISTER_MESSAGE,OTP_MESSAGE,LOGIN_MESSAGE,PROFILE_MESSAGE,COMPLAINT_MESSAGE,PROFILES,PRIVACY_POLICY,TERMS_CONDITIONS,REFUND_POLICY, GET_ALL_HOME_DATA, GET_ALL_HOME_DATA_ERROR,EDIT_USER_DETAILS_MESSAGE,CHANGE_PASSWORD_MESSAGE,ABOUT_US,LOGOUT_MESSAGE,LIVE_STREAMING_DASHBOARD_DATA_ERROR,LIVE_STREAMING_DASHBOARD_DATA,GET_STREAM_DETAILS,GET_STREAM_DETAILS_ERROR, USER_DETAILS, FORGOT_PASSWORD_MESSAGE, NOTIFICATIONS, USER_LOGIN_LIST, USER_LOGOUT_MESSAGE, RUNNING_STATUS, FORGOT_PROFILE_PIN_MESSAGE } from "../../Actiontype/ActionType"
const initstate = { slider:null,movieDetails:null,homeData:null }

const HomeStore = (state = initstate, action) => {

    const { type, payload } = action

    switch (type) {

        case GET_SLIDER_DATA: {
            return {
                ...state,
                slider: payload
            }
        }
        case GET_SLIDER_DATA_ERROR: {

            return {
                ...state,
                slider: null
            }
        }
        case GET_ALL_HOME_DATA: {
            return {
                ...state,
                homeData: payload
            }
        }
        case GET_ALL_HOME_DATA_ERROR: {

            return {
                ...state,
                homeData: null
            }
        }
        case LIVE_STREAMING_DASHBOARD_DATA: {
            return {
                ...state,
                liveStreamData: payload
            }
        }
        case LIVE_STREAMING_DASHBOARD_DATA_ERROR: {

            return {
                ...state,
                liveStreamData: null
            }
        }
        
        case GET_STREAM_DETAILS: {
            return {
                ...state,
                streamDetails: payload
            }
        }
        case GET_STREAM_DETAILS_ERROR: {

            return {
                ...state,
                streamDetails: null
            }
        }
        case GET_MOVIE_DETAILS: {
            return {
                ...state,
                movieDetails: payload
            }
        }
        case USER_DETAILS:
            {
                return {
                    ...state,
                    user_details: payload
                }
            }
        case GET_MOVIE_DETAILS_ERROR: {

            return {
                ...state,
                movieDetails: null
            }
        }
      
        case REGISTER_MESSAGE: {

            return {
                ...state,
                register_message: payload
            }
        }
        case EDIT_USER_DETAILS_MESSAGE: {

            return {
                ...state,
                edit_user_details_message: payload
            }
        }
        case CHANGE_PASSWORD_MESSAGE: {

            return {
                ...state,
                change_password_message: payload
            }
        }
        case FORGOT_PASSWORD_MESSAGE:
            {
            return {
                ...state,
                forgot_password_message: payload
            }
        }

        case OTP_MESSAGE: {

            return {
                ...state,
                otp_message: payload
            }
        }
        case PROFILE_MESSAGE: {

            return {
                ...state,
                profile_message: payload
            }
        }
        case FORGOT_PROFILE_PIN_MESSAGE: {

            return {
                ...state,
                forgot_profile_pin_message: payload
            }
        }
        case LOGIN_MESSAGE: {
            console.log("heheheheheeee")
            return {
                ...state,
                login_message: payload
            }
        }
        case LOGOUT_MESSAGE:
            {

                return {
                    ...state,
                    logout_message: payload
                }
            }
        case COMPLAINT_MESSAGE: {

            return {
                ...state,
                complaint_message: payload
            }
        }
        case AVATARS: {

            return {
                ...state,
                avatars: payload
            }
        }
        case PROFILES: {

            return {
                ...state,
                profiles: payload
            }
        }
        case PRIVACY_POLICY: {

            return {
                ...state,
                privacy_policy: payload
            }
        }
        case TERMS_CONDITIONS: {

            return {
                ...state,
                terms_conditions: payload
            }
        }
        case REFUND_POLICY: {

            return {
                ...state,
                refund_policy: payload
            }
        }
        case ABOUT_US: {

            return {
                ...state,
                about_us: payload
            }
        }
        case NOTIFICATIONS:
            {

                return {
                    ...state,
                    notifications: payload
                }
            }
            case USER_LOGIN_LIST:
                {
                    return{
                        ...state,
                        user_login_list:payload
                    }
                }
                case USER_LOGOUT_MESSAGE:
                    {
                        return{
                            ...state,
                            user_logout_message:payload
                        }
                    }
                    case RUNNING_STATUS:
                        {
                            return{
                                ...state,
                                running_status:payload
                            }
                        } 
        default:
            return state
    }

}


export default HomeStore