import axios from 'axios'
import { GET_SUBSCRIPTION_DETAILS_API,payment_api, transaction_history_api, change_password_api, check_promocode_api } from '../constant/ApiList'
import { GET_SUBSCRIPTION_DETAILS, GET_SUBSCRIPTION_DETAILS_ERROR,PAYMENT_MESSAGE,TRANSACTIONS,PROMOCODE } from '../Actiontype/ActionType'

export const getSubscriptionDetails = () => {
return async(dispatch)=>{
    return await axios
    .post(GET_SUBSCRIPTION_DETAILS_API)
    .then((res)=>{
        dispatch({
            type:GET_SUBSCRIPTION_DETAILS,
            payload:res.data
        })
        return res.data
    }).catch((error)=>{
        dispatch({
            type:GET_SUBSCRIPTION_DETAILS_ERROR
        })
        return error.response
    })
}
}
export const payment = (formData) => async (dispatch) => {
    try {
      const { data } = await payment_api(formData);
      console.log(data,"payment action")
      dispatch({ type: PAYMENT_MESSAGE, payload:data });
      return data
   
    } catch (error) {
      console.log(error);
    }
  };
  export const check_promocode = (formData) => async (dispatch) => {
    try {
      const { data } = await check_promocode_api(formData);
      console.log(data,"PROMOCODE action")
      dispatch({ type: PROMOCODE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const transaction_history = (formData) => async (dispatch) => {
    try {
      const { data } = await transaction_history_api(formData);
      console.log(data,"payment action")
      dispatch({ type: TRANSACTIONS, payload:data });
      dispatch({ type: PROMOCODE, payload:null });
  
   
    } catch (error) {
      console.log(error);
    }
  };