import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form } from 'react-bootstrap'

import { Link, useHistory } from 'react-router-dom'
import "./form.css"
// rtl 
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import bg from "../../../../assets/images/signup_background.jpg"
import ListTable from './table';
import user_pic from "../../../../assets/images/user/user.jpg"
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import { transaction_history } from '../../../../actions/PaymentActions';
import { ROUTES } from '../../../../constant/routes';

const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
const Transaction_History = (props) => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const profile = JSON.parse(sessionStorage.getItem('profile'))

    const dispatch = useDispatch()
    let history = useHistory()
    const [show, setShow] = useState(false);

    useEffect(() => {
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.rtlModeAction(props.rtlMode)
        }
        else {
            props.rtlModeAction(rtlMode);
        }
    })
    useEffect(() => {
        dispatch(transaction_history({ user: user?.id }))
    }, [])
    const transactions = useSelector((state) => state.payment.transactions)
    console.log(transactions, "dcs")
    var tableData = {
        tableTitle: "Transactions",
        setSearch:false,

        tableHead: [
            {
                id: "plan",
                label: "Plan",
                subText: "plan_name"
            },
            {
                id: "payment_amount",
                label: "Amount",
            },
            {
                id: "promocode",
                label: "Promocode",
            },
            {
                id: "payment_id",
                label: "Payment ID",
            },
            {
                id: "created_at",
                label: "Payment Date",
            },
            {
                id: "device_type",
                label: "Device Type",
            },

            {
                id: "location",
                label: "Location",
            }
        ],
        tableBody: [
            {
                id: 0,
                slider_type: "Landscape",
                app_banner: "Landscape",
                web_banner: "Landscape",
                videoType: "Landscape",
                set_sequence: "2",
                status: "Active"
            },
        ],

    };

    console.log(tableData)

    useMemo(() => {
        tableData["tableBody"] = transactions?.data?.map((ele) => ({ ...ele, payment_amount: `$ ${Number(ele?.payment_amount).toFixed(2)}`, plan: ele?.transaction_type == "SVOD" ? ele?.plan?.plan_name : "TVOD", plan_name: ele?.transaction_type == "TVOD" && (ele?.movie?.movie_name || ele?.series?.series_name) })) || []
    }, [transactions])
    const goBack = () => {
        history.push(ROUTES.my_account)
    }

    return (
        <>
            <section className="sign-in-page" style={{marginTop:"5rem"}}>

                <Container >
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="12" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">




                                        <p style={{ textAlign: "center", marginBottom: "1rem", fontWeight: "400", fontSize: "24px" }}>Transaction History</p>

                                        <ListTable
                                            tableData={tableData}
                                            key={"ListTable"}   
                                        />

                                        <div style={{ cursor: "pointer" }} onClick={goBack}><i className="fa fa-arrow-left" aria-hidden="true"></i>  Back</div>



                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Transaction_History)