import React, { useState, useEffect } from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import "./style.css"
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import * as PaymentActions from '../../../../actions/PaymentActions'
import * as HomeActions from '../../../../actions/HomeActions'
import { toast } from 'react-toastify'
const right = <i className="fa fa-check-circle-o"></i>
const wrong = <i className="fa fa-times-circle"></i>

const SVOD = (props) => {
    const dispatch = useDispatch()
    const user = JSON.parse(sessionStorage.getItem('user'))
    const { getSubscriptionDetails } = bindActionCreators(PaymentActions, dispatch)
    const { user_details_web } = bindActionCreators(HomeActions, dispatch)
    const [currentPlan, setCurrentPlan] = useState("")
    const user_details = useSelector((state) => state.home.user_details?.data)
    const [disableBtn, setDisableBtn] = useState(false)
    const [userDetails, setUserDetails] = useState("")

    const [plan, setPlan] = useState([])
    // useEffect(() => {
    //     (async () => {
    //         // dispatch(user_details_web({ user_id: user?.id }))

    //     })()


    // }, [])
    useEffect(() => {
        (async () => {
            const resData = await getSubscriptionDetails()
            const userData = await user_details_web({ user_id: user?.id })
            let localArr = []
            setUserDetails(userData)
            console.log(userData, resData)
            // console.log("resData", resData)
            resData?.data?.filter((data) => {
                if (data?.status !== "Inactive") {
                    console.log(data)
                    localArr.push(data)

                }
            })
            setPlan(localArr)

            resData?.data?.map((data) => {
                if (userData?.data?.subscription) {
                    if (data?.id == userData?.data?.subscription?.id) {

                        if (Number(data?.country_price?.[0]?.price) == Number(resData?.data?.[resData?.data?.length - 1]?.country_price?.[0]?.price)) {
                            setDisableBtn(true)
                        }
                        console.log("data", data)


                        setCurrentPlan(data)
                    }
                }
            })
            props?.setSelectedPlan(resData?.data[resData?.data?.length - 1])
        })();
    }, [])

    console.log(plan)
    const pricingLists = plan?.[0]?.content?.map((value, index) => (
        {
            title: plan?.[0]?.content?.[index]?.content,
            value: plan?.map((ele) => {
                if (ele?.content?.[index]?.content_value == "true")
                    return right
                else if (ele?.content?.[index]?.content_value == "false")
                    return wrong
                else
                    return ele?.content?.[index]?.content_value
            })
        }
    ))

    const handlePlanSelect = (data) => {
        console.log("comes", data)
        if (userDetails?.data?.subscription) {
            if (Number(data?.country_price?.[0]?.price) > Number(currentPlan?.country_price?.[0]?.price)) {
                setDisableBtn(false)
            }
            else {
                setDisableBtn(true)
            }
        }


        props?.setSelectedPlan(data)
    }

    // console.log(disableBtn)
    return (
        <>


            <div className="iq-pricing-card-two">
                <div className="table-responsive iq-pricing pt-2">
                    <Table id="my-table" className="table" data-active="premium">
                        <col></col>
                        {
                            plan?.map((data, index) => (
                                <col key={index} style={{ background: props?.selectedPlan?.plan_name === data?.plan_name ? "linear-gradient(180deg,#252833 0,#0f1014 100%)" : "" }}></col>
                            ))
                        }
                        <thead>
                            <tr>
                                <th className="text-center iq-price-head"></th>
                                {
                                    plan?.map((data, index) => (
                                        <th key={index} className="text-center iq-price-head free" style={{ borderTopLeftRadius: ".5rem", borderTopRightRadius: ".5rem" }}>
                                            <div className={`iq-price-box`} onClick={() => handlePlanSelect(data)}>
                                                <span className="" style={{ fontSize: "13px", color: props?.selectedPlan?.plan_name === data?.plan_name ? "#ffcc75" : "" }}>{data?.plan_name}</span><br /><br />
                                                <span className="iq-price-rate" style={{ fontSize: "13px" }}>{data?.country_price?.[0]?.country_currency_symbol}{data?.country_price?.[0]?.price}<small style={{ fontSize: "13px" }}> / {data?.duration}</small></span>

                                            </div>
                                        </th>
                                    ))
                                }


                            </tr>
                        </thead>
                        <tbody>
                            {pricingLists?.map((item, index) => (
                                <tr key={item.title}>
                                    <th className="text-left" scope="row" style={{ fontSize: "13px" }}> {item.title}</th>
                                    {item.value.map((ele, index) => <td onClick={() => handlePlanSelect(plan?.[index])} key={index} style={{ fontSize: "13px" }} className={`text-center iq-child-cell`} >
                                        {ele}
                                    </td>)}

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="iq-price-bottom">
                    <Row className="iq-select-price">
                        <Col lg="3" md="6" sm="12" >
                        </Col>

                        {/* {
                            plan?.map((data, index) => (
                                <Col key={index} lg="3" md="6" sm="12" className="free">
                                    <div className={`iq-price-rate-wrap ${props?.selectedPlan?.plan_name === data?.plan_name ? 'active' : ''}`} onClick={() => handlePlanSelect(data)}>
                                        <div style={{ background: "black", width: "100%", padding: "1rem", borderRadius: "1rem" }}>
                                            <i className="fa fa-check-square"></i>
                                            <div className="iq-price-label">
                                                <span className="type1" style={{ color: props?.selectedPlan?.plan_name === data?.plan_name ? "#ffcc75" : "", fontSize: "13px" }}>{data?.plan_name}</span><br />
                                                <span className="iq-price-rate" style={{ fontSize: "13px" }}>{data?.country_price?.[0]?.country_currency_symbol}{data?.country_price?.[0]?.price}<small style={{ fontSize: "13px" }}> / {data?.duration}</small></span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        } */}


                    </Row>
                    <div className="align-items-center r-mb-23" data-animation-in="fadeInUp" data-delay-in="1.3">
                        {/* <Link to="#" className="btn btn-hover iq-button">Subscribe</Link> */}
                        <button className={"btn btn-hover iq-button"} onClick={() => props?.selectedPlan?.country_price?.[0]?.price ? props?.setCheckoutPage(prev => !prev) : toast.error("Select any subscription plan to continue")} disabled={disableBtn}>{disableBtn ? `Current Plan: ${currentPlan?.plan_name}` : "Proceed to pay"}</button>
                    </div>
                </div>
            </div>


        </>
    )
}
export default SVOD;