import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Nav, Tab, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

import * as MovieAction from '../../../actions/MovieAction'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import { useHistory } from 'react-router-dom';
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

export default function Language(props) {
    const history = useHistory()

    const handleLanguageId = (id) => {
        history.push(ROUTES.moviesByLanguage, { id: id })
    }
    return (
        <div className='main-content'>
            <section id="iq-favorites">
                <Container fluid>
                    <Row>
                        <Col sm="12" className="overflow-hidden">
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 style={{ textTransform: "uppercase" }} className="main-title">{props?.languages?.Name}</h6>
                                {/* <Link className="iq-view-all" to="/movie-category">View All</Link> */}
                            </div>
                            <div id="favorites-contens">
                                {/* <div id="prev2" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                        <div id="next2" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                <Swiper
                                    navigation={{
                                        prevEl: '#prev2',
                                        nextEl: '#next2'
                                    }}
                                    breakpoints={{
                                        320: { slidesPerView: 2 },
                                        450: { slidesPerView: 3 },
                                        638: { slidesPerView: 4 },
                                        991: { slidesPerView: 5 },
                                        1300: { slidesPerView: 6 }
                                        // 1400: { slidesPerView: 4 },
                                        // 1800: { slidesPerView: 5 },
                                    }}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    autoplay={{
                                        delay: 1000,
                                    }}
                                    // loop={(movies?.length > 4) ? true : false}
                                    loop={true}
                                    slidesPerView={6}
                                    spaceBetween={0}
                                    as="ul"
                                    className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">
                                    {
                                        props?.languages?.data?.map((data, index) => (
                                            <SwiperSlide as="li" key={index}>
                                                <div onClick={() => handleLanguageId(data?.id)}>
                                                    <img src={`${IMAGE_ENDPOINT}/${data?.language_image}`} className="language-img genre-border" alt={data?.language_image} />
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    }

                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
