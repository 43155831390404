import React from "react";
import { Link } from "react-router-dom";
import logo from '../../../assets/regal_images/regal_logo.png'
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    TelegramIcon
} from "react-share";

function Share(props) {

    return (
        <>
            <div className="mt-1" >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={logo} style={{ width: "100px" }}></img>
                </div>
                <hr style={{ color: "white" }} />
                <p style={{ color: "white" }}>Share this link via</p>
                <TwitterShareButton url={props?.sharedURL}>
                    <TwitterIcon size={35} round={true} style={{ margin: "5px" }} />
                </TwitterShareButton>

                <FacebookShareButton url={props?.sharedURL}>
                    <FacebookIcon size={35} round={true} style={{ margin: "5px" }} />
                </FacebookShareButton>

                <TelegramShareButton url={props?.sharedURL}>
                    <TelegramIcon size={35} round={true} style={{ margin: "5px" }} />
                </TelegramShareButton>

                <WhatsappShareButton url={props?.sharedURL}>
                    <WhatsappIcon size={35} round={true} style={{ margin: "5px" }} />
                </WhatsappShareButton>

                <LinkedinShareButton url={props?.sharedURL}>
                    <LinkedinIcon size={35} round={true} style={{ margin: "5px" }} />
                </LinkedinShareButton>

                <EmailShareButton url={props?.sharedURL}>
                    <EmailIcon size={35} round={true} style={{ margin: "5px" }} />
                </EmailShareButton>
                <div style={{display:"flex",justifyContent:"space-around"}}>
                    <p style={{ color: "white" }} className="mt-3">Or copy link</p>

                    <button
                        className="btn btn-hover iq-button mt-2"
                        onClick={() => navigator.clipboard.writeText(props?.sharedURL)}>
                        <i className="fa fa-link mr-2" aria-hidden="true"></i>Copy Url
                    </button>
                </div>

            </div>

        </>
    );
}
export default Share;
