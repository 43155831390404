import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Col, Row, Nav, Tab, } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { gsap } from 'gsap'
import logo from '../../../assets/regal_images/regal_logo.png'
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as HomeActions from "../../../actions/HomeActions";
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Share from '../common/Share';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as MovieAction from '../../../actions/MovieAction'
import * as HomeAction from '../../../actions/HomeActions'
import AlertMessage from '../common/AlertMessage';
import { toast } from 'react-toastify';
import plus from '../../../assets/regal_images/plus.png'
import check from '../../../assets/regal_images/check.png'
import SwiperCore, { Autoplay, Pagination, Navigation, EffectCreative } from "swiper";
import { Box } from '@mui/material';
import VideoJS from '../../../player/player'
import videojs from 'video.js';
import animatedGIF from '../../../assets/regal_images/Animation.gif'
import { EffectCoverflow } from 'swiper';


import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};
const styleforAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 600,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const gsapAnimate = {
    getData: (elem) => {
        const option = {
            opacity: 0,
            scale: 1,
            position: {
                x: 0,
                y: 0,
            },
            ease: "",
            duration: 1,
            delay: .4,
            rotate: 0
        }
        if (elem !== undefined) {
            option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)
            option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)
            option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)
            option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)
            option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)
            option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)
            option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)
            option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')
            const setOption = { opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay }
            return setOption
        } else {
            return { opacity: 0 }
        }
    },
    onStart: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.from(elem, setOption)
    },
    onEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.to(elem, setOption)
    },

    onStartEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        const setEndOption = gsapAnimate.getData(elem)
        setEndOption.opacity = 1
        setEndOption.x = 0
        setEndOption.y = 0
        setEndOption.rotate = 0
        setEndOption.scale = 1
        gsap.fromTo(elem, setOption, setEndOption)
    },
    validValue: (attr, defaultVal) => {
        if (attr !== undefined && attr !== null) {
            return Number(attr)
        }
        return Number(defaultVal)
    }
}

function Slider() {
    const playerRef = React.useRef(null);

    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [slug, setSlug] = useState("")
    const [watchListopen, setWatchlistOpen] = React.useState(false);
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const [save, setSave] = useState(false)
    const { addIntoWatchList } = bindActionCreators(MovieAction, dispatch)
    const { setCompleteHours } = MovieAction

    const { getSliderData } = bindActionCreators(HomeAction, dispatch)
    const [popupOpen, setPopupOpen] = useState(false)
    const [selectedMovie, setSelectedMovie] = useState()
    const { removeFromWatchlist } = MovieAction
    const history = useHistory()
    const [sliderData, setSliderData] = useState([])
    const [count, setCount] = useState(-2);
    SwiperCore.use([Autoplay, EffectCoverflow])
    const [animationStart, setAnimationStart] = useState(false)
    const [hoursLeft, setHoursLeft] = useState(calculateHoursLeft());
    const [hoursAlert, setHoursAlert] = useState(false)
    const [completedHour, setCompletedHour] = useState(false)
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const [tempData, setTempData] = useState("")
    let maxMovieWatchFlag = false
    useEffect(() => {
        (async () => {
            let userObj = {
                profile_id: profile_id ? profile_id : "null"
            }
            const sliderRes = await getSliderData(userObj)
            console.log(sliderRes)
            if (sliderRes?.statuscode == 200) {

                setSliderData(sliderRes?.data)

            }
            const intervalId = setInterval(() => {
                setHoursLeft(calculateHoursLeft());
            }, 1000);
        })();
    }, [save])

    function calculateHoursLeft() {
        const now = new Date();
        const nextDay = new Date(now);
        nextDay.setDate(now.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);

        const timeDifference = nextDay - now;
        const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));

        return hoursLeft;
    }

    const handleClick = (event, slug) => {
        setAnchorEl(event.currentTarget);
        setSlug(slug)
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
    };

    const animationInit = () => {
        if (document.querySelector('.swiper-container .swiper-slide-active') !== null) {

            const gsapElem = document.querySelector('.swiper-container .swiper-slide-active').querySelectorAll('[data-iq-gsap="onStart"]')

            Array.from(gsapElem, (elem) => {
                return gsapAnimate.onStartEnd(elem)
            })
        }
    }

    const AddWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_ID: profile_id,
                movie: id
            }
            const res = await addIntoWatchList(userObj)
            if (res?.statuscode == 200) {
                setAnimationStart(true)
                setTimeout(() => {
                    setAnimationStart(false)
                }, 2000);
                toast.success(res?.message)
                setSave(!save)

            }
            else {
                toast.error("Something went wrong.")
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    };

    const RemoveWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_ID: profile_id,
                movie: id,
                series_id: ""
            }
            const res = await removeFromWatchlist(userObj)
            if (res.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    }

    const handleRedirect = (slug) => {
        history.push(`${ROUTES.moviedetails}/${slug}`)
    }
    const handlePopupClose = () => {
        setPopupOpen(false);
    };
    const handlePlayNow = (data) => {
        console.log(data)
        if (!isLoggedIn) {
            setPopupOpen(true)

        }
        else {
            if (data?.max_movie_watch_complete == "true" && data?.movie?.movie_access == "SVOD") {
                setPopupOpen(true)
                setSelectedMovie(data)

            }
            else {
                if (data?.movie?.movie_access == "TVOD" && data?.get_status == "false") {
                    setHoursAlert(true)
                    setTempData(data)
                }
                else if (data?.movie?.movie_access == "TVOD" && data?.get_status == "completed") {
                    setCompletedHour(true)
                }
                else {
                    if (data?.movie?.movie_is_for18Plus == "Yes") {
                        if (!popupOpen) {
                            setSelectedMovie(data)
                            setPopupOpen(true)
                        }
                        else {
                            history.push(ROUTES.video, { movie: { ...data, Data: data?.movie }, resume: data?.last_watching_time })
                        }
                    }
                    else {
                        history.push(ROUTES.video, { movie: { Data: data?.movie }, resume: data?.last_watching_time })
                    }
                }

            }
        }

        // else {
        //     if (data?.movie?.movie_is_for18Plus == "Yes") {
        //         if (!popupOpen) {
        //             setSelectedMovie(data)
        //             setPopupOpen(true)
        //         }
        //         else {
        //             history.push(ROUTES.video, { movie: { ...data, Data: data?.movie } })
        //         }

        //     }
        //     else {
        //         history.push(ROUTES.video, { movie: { Data: data?.movie } })
        //     }
        // }
    }
    const handlePlayerReady = (player) => {
        playerRef.current = player;
        console.log("video play")
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    const handleHoursDialogueClose = () => {
        setHoursAlert(false);
        setCompletedHour(false)
    };

    const handleHoursContinue = async () => {
        const send_status = await setCompleteHours({
            user: user_id,
            movie: tempData?.movie?.id
        })
        if (send_status?.statuscode == 200) {
            history.push(ROUTES.video, { movie: { Data: tempData?.movie } })
        }

    }


    return (
        <>
            {/* <AlertMessage /> */}
            <Modal
                open={popupOpen}
                onClose={handlePopupClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {!isLoggedIn ? <Box sx={style}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Please login to continue.
                        </Typography>
                        <button onClick={() => history.push("/auth/login")} className={"btn btn-hover iq-button mt-3"} style={{ display: "block", margin: "0 auto" }}>
                            Login
                        </button>
                    </div>
                </Box> :
                    (selectedMovie?.max_movie_watch_complete == "true" ?
                        <Box sx={style}>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src={logo} width={"100px"} />
                            </div>
                            <hr />

                            <div className="login-popup" >
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                                    You have already seen this film maximum number of times. To watch it again, purchase a Diamond Plan.
                                </Typography>
                                <div className='mt-3' style={{ display: "flex", justifyContent: "center" }}>

                                    <button onClick={handlePopupClose} className={"btn btn-hover iq-button ml-2"} >
                                        Okay
                                    </button>
                                </div>
                            </div>
                        </Box> : <Box sx={style}>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src={logo} width={"100px"} />
                            </div>
                            <hr />

                            <div className="login-popup" >
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                                    Please confirm you are 18+.
                                </Typography>
                                <div className='mt-3' style={{ display: "flex", justifyContent: "end" }}>
                                    <button onClick={() => handlePlayNow(selectedMovie)} className={"btn btn-hover iq-button"}>
                                        Yes
                                    </button>
                                    <button onClick={handlePopupClose} className={"btn btn-hover iq-button ml-2"} >
                                        No
                                    </button>
                                </div>
                            </div>
                        </Box>)
                }
            </Modal>
            <Modal
                open={hoursAlert}
                onClose={(handleHoursDialogueClose)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Once you Start watching Movie, It must be finished within 72 hours or it will automatically be removed.
                        </Typography>
                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1.5rem" }}>

                            <button onClick={handleHoursContinue} className={"btn btn-hover iq-button"}>
                                Watch Now
                            </button>
                            <button onClick={handleHoursDialogueClose} className={"btn btn-hover ml-3 iq-button"}>
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={completedHour}
                onClose={(handleHoursDialogueClose)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            You have exceeded 72 hours of movie watching limitation , please rent now to continue                         </Typography>
                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1.5rem" }}>
                            <button onClick={(handleHoursDialogueClose)} className={"btn btn-hover ml-3 iq-button"}>
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
                {/* <div id="prev5" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                <div id="next5" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                <Swiper

                    navigation={{
                        prevEl: '#prev5',
                        nextEl: '#next5'
                    }}

                    pagination={{
                        "clickable": true
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    autoplay={{
                        delay: 6000,
                    }}
                    speed={1500}
                    effect='coverflow'
                    onInit={() => { animationInit() }}
                    onSlideChangeTransitionStart={() => animationInit()}
                    onSlideChange={(swiper) => {

                        if (sliderData[count]?.slider_type == "Video") {

                            swiper.autoplay.stop();
                            playerRef.current.src(sliderData[count]?.video_url)
                            playerRef.current.play()
                            playerRef.current.on('ended', () => {
                                swiper.autoplay.start();
                            });

                        }
                        if (count == sliderData?.length - 1)
                            setCount(-1);
                        else {
                            setCount((prev) => prev + 1);
                        }
                    }}
                    loop={true}
                    id="home-slider"
                    className="slick-bg-1 m-0 p-0 slider-bg">
                    {
                        sliderData?.map((data, index) => (

                            <SwiperSlide
                                className="slide slick-bg"
                                key={index}
                                style={{ backgroundImage: `url(${IMAGE_ENDPOINT + (data?.web_banner || data?.movie?.movie_poster)})` }}
                            // onClick={() => handleRedirect(data?.movie?.slug)}
                            >
                                <div className='bg-video ' >
                                    {data?.slider_type == "Video" && data?.video_url &&
                                        <>
                                            <VideoJS
                                                style={{
                                                    position: "absolute",
                                                }}
                                                options={{
                                                    poster: `${IMAGE_ENDPOINT + data?.movie?.movie_poster}`,

                                                    sources: [
                                                        {
                                                            src: `${data?.video_url}`,
                                                            type: 'video/mp4',
                                                        }
                                                    ]
                                                }} onReady={handlePlayerReady} /><div style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    width: "100vw",
                                                    height: "100vh",
                                                    background: "linear-gradient(90deg, rgba(20, 20, 20, 1) 0%, rgba(36, 36, 36, 1) 15%, rgba(83, 100, 141, 0) 60%)"
                                                }}></div></>}



                                    <Container fluid className="position-relative h-100">
                                        <div className="slider-inner h-100">
                                            <Row className="  iq-ltr-direction h-100">
                                                <Col xl="6" lg="12" md="12">
                                                    {
                                                        data?.movie?.movie_logo ?
                                                            <Link to={`${ROUTES.moviedetails}/${data?.movie?.slug}`}>
                                                                <div className="channel-logo pb-3" data-iq-delay="0.5">
                                                                    <img src={`${IMAGE_ENDPOINT + data?.movie?.movie_logo}`} className="c-logo" alt="streamit" style={{ height: "100px", width: "250px" }} />
                                                                </div>
                                                            </Link>
                                                            :
                                                            <h2>{data?.movie?.movie_name}</h2>
                                                    }
                                                    {/* <Link to={`${ROUTES.moviedetails}/${data?.movie?.slug}`}>
                                                    <h2 className="slider-text big-title title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">{data?.movie?.movie_name}</h2>
                                                </Link> */}
                                                    <div className="d-flex flex-wrap align-items-center">
                                                        <Link to={`${ROUTES.moviedetails}/${data?.movie?.slug}`}>
                                                            <div className="d-flex flex-wrap align-items-center">
                                                                <p dangerouslySetInnerHTML={{ __html: data?.movie?.movie_description?.length <= 250 ? data?.movie?.movie_description : data?.movie?.movie_description?.substring(0, 250) + "..." }}>
                                                                </p>
                                                            </div>
                                                        </Link>

                                                        <div className="trending-list" data-wp_object-in="fadeInUp" data-delay-in="1.2" onClick={() => handleRedirect(data?.movie?.slug)}>
                                                            <p>
                                                                <b>Language: </b><span className='span-tag'>{data?.movie?.movie_language}</span>
                                                            </p>
                                                            <p style={{ marginTop: "-0.5rem" }}>
                                                                <b>Duration: </b><span className='span-tag'>{Number((data?.movie?.movie_duration).split(":")[0]) + "h " + Number((data?.movie?.movie_duration).split(":")[1]) + "m"}</span>
                                                            </p>
                                                            <p style={{ marginTop: "-0.5rem" }}>
                                                                <b>Genres:</b> {data?.movie?.movie_genre?.map((data, index) => (<span className='span-tag' key={index}>{(index ? ', ' : '') + data.genre_title}</span>))}
                                                            </p>
                                                            <p style={{ marginTop: "-0.5rem" }}>
                                                                <b>Starring:</b> {data?.movie?.movie_cast?.map((data, index) => (<span className='span-tag' key={index}>{(index ? ', ' : '') + data.cast_name}</span>))}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex align-items-start r-mb-23" style={{ marginTop: "-1rem" }}>
                                                        {data?.movie?.publish_type == "Later" && new Date() < new Date(data?.movie?.release_date + "T" + data?.movie?.publish_time + "Z") ?
                                                            <p className='upcoming-p' >RELEASING ON <br /><span >{new Date(data?.movie?.release_date + "T" + data?.movie?.publish_time + "Z").toLocaleDateString('en-GB', { timeZone: 'Europe/London' })}&nbsp;|&nbsp;{new Date(data?.movie?.release_date + "T" + data?.movie?.publish_time + "Z").toLocaleTimeString('en-IN', { timeZone: 'Europe/London', hour: 'numeric', minute: "2-digit", hour12: true })}</span></p>

                                                            :
                                                            <div style={{ marginRight: ".5rem" }}>
                                                                {data?.movie?.movie_access == "SVOD" &&
                                                                    (data?.is_subscription_expiry ?
                                                                        <button className="btn btn-hover iq-button" onClick={() => history.push("/price-plan")}>
                                                                            <i className="fa fa-play mr-2" aria-hidden="true"></i>Subscribe Now
                                                                        </button> :

                                                                        <button className="btn btn-hover iq-button"
                                                                            onClick={() => handlePlayNow(data)}

                                                                        >
                                                                            <i className="fa fa-play mr-2" aria-hidden="true"></i>Play
                                                                        </button>)
                                                                }
                                                                {data?.movie?.movie_access == "FREE" &&
                                                                    <button className="btn btn-hover iq-button"
                                                                        onClick={() => handlePlayNow(data)}

                                                                    >
                                                                        <i className="fa fa-play mr-2" aria-hidden="true"></i>Play
                                                                    </button>
                                                                }

                                                                {data?.movie?.movie_access == "TVOD" &&
                                                                    (data?.is_rental_movie_expiry ?
                                                                        <>
                                                                            <button className={"btn btn-hover iq-button"} onClick={() => history.push("/price-plan", { transactionType: "TVOD", available_for: data?.movie?.available_for_days, movieName: data?.movie?.movie_name, movie: data?.movie?.id, categoryType: "Movie", price: data?.movie?.rental_price, ads: data?.advertise })}>Rent Now : ${Number(data?.movie?.rental_price).toFixed(2)}</button>
                                                                        </>
                                                                        :
                                                                        <div >
                                                                            <button className="btn btn-hover iq-button"
                                                                                onClick={() => handlePlayNow(data)}
                                                                            >
                                                                                <i className="fa fa-play mr-2" aria-hidden="true"></i>Play

                                                                            </button>
                                                                            <span style={{ fontSize: "12px", display: "flex", justifyContent: "center", marginTop: ".3rem", textTransform: "none" }}>{data?.days_total != 0 ? data?.days_total : ""} {data?.days_total > 1 ? "days" : data?.days_total == 0 ? `${hoursLeft} hours` : "day"} remaining</span>
                                                                        </div>

                                                                    )}
                                                            </div>}

                                                        {/* <Link to={`${ROUTES.moviedetails}/${data?.movie?.slug}`} className="btn btn-hover iq-button ">
                                                            <i class="fa fa-info" style={{ padding: "2px 3px", fontSize: "12px", paddingLeft: "5px", border: "3px solid white", borderRadius: "50%", marginRight: ".5rem" }}></i>More Info
                                                        </Link> */}
                                                        <Link to={`${ROUTES.moviedetails}/${data?.movie?.slug}`} >
                                                            {/* <i className="fa fa-share icon-border ml-2"></i> */}
                                                            <div className='icon-border-details ml-1'>
                                                                <i className="fa fa-info details-con" aria-hidden="true"></i>  Details

                                                            </div>
                                                        </Link>
                                                        {
                                                            data?.isWatchlist ?
                                                                <div onClick={() => RemoveWatchList(data?.movie?.id)} className='ml-2' style={{ cursor: "pointer" }}>
                                                                    <i className="fa fa-check icon-border" aria-hidden="true"></i>
                                                                    {/* <img src={check} style={{ height: "30px", cursor: "pointer" }} alt='add Watchlist' /> */}

                                                                </div>
                                                                :
                                                                <div onClick={() => AddWatchList(data?.movie?.id)} className='ml-2' style={{ cursor: "pointer", position: "relative" }}>
                                                                    <i className="fa fa-plus icon-border"></i>
                                                                    {/* <img src={plus} style={{ height: "30px", cursor: "pointer" }} alt='add Watchlist' /> */}
                                                                    <div>
                                                                        <img style={{ display: `${animationStart ? "" : "none"}`, position: "absolute", top: "0", width: "60px" }} src={animatedGIF} alt='animated' />
                                                                    </div>
                                                                </div>
                                                        }

                                                        <Link to="/watchlist">
                                                        </Link>

                                                        <div onClick={(event) => handleClick(event, data?.movie?.slug)} style={{ cursor: "pointer" }}>
                                                            <i className="fa fa-share icon-border ml-2"></i>
                                                        </div>


                                                        {/* <div onClick={(event) => handleClick(event, data?.movie?.slug)}>
                                                        <i className="fa fa-share-alt share-icon ml-3" aria-hidden="true"></i>
                                                    </div> */}



                                                        <Popover
                                                            id={id}
                                                            open={open}
                                                            anchorEl={anchorEl}
                                                            onClose={handleClose}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                        >
                                                            <Typography sx={{ p: 2 }}>
                                                                <Share sharedURL={`${window.location.href}movie-details/${slug}`} />
                                                            </Typography>
                                                        </Popover>

                                                    </div>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Container>
                                </div>
                            </SwiperSlide>
                        ))
                    }


                </Swiper>
            </section>

        </>
    )
}
export default Slider