import React, { useEffect, useState, useMemo } from 'react'
import VideoJS from './player'
import videojs from 'video.js';
import { useLocation } from 'react-router-dom';
import 'video.js/dist/video-js.css';
import { useHistory } from 'react-router-dom';
import "videojs-markers"
import { useDispatch, useSelector } from 'react-redux';
// import { advertise_seen_count_change } from '../actions/MovieAction';
import { bindActionCreators } from 'redux';
import * as MovieAction from '../actions/MovieAction'
import left_arrow from "../assets/regal_images/left-arrow.png"
import logo from '../assets/regal_images/regal_logo.png'


import axios from "axios";
import { Box, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { RUNNING_STATUS } from '../Actiontype/ActionType';
import * as HomeAction from '../actions/HomeActions';
import { IMAGE_ENDPOINT } from '../constant/ApiList';
import { ROUTES } from '../constant/routes';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: "70vh",
  width: 400,
  bgcolor: 'rgb(25,25,25)',
  border: '2px solid rgb(255,255,255,0.5)',
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

require("@silvermine/videojs-quality-selector")(videojs);

export default function VideoFile() {

  const playerRef = React.useRef(null);
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [movieData, setMovieData] = useState(location?.state?.movie)
  const [adsFlag, setAdsFlag] = useState(location?.state?.movie?.advertise?.map((ele) => true) || [])
  const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
  const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
  const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
  const { addContinueWatching } = bindActionCreators(MovieAction, dispatch)
  const { change_running_status, getMovieDetails } = bindActionCreators(HomeAction, dispatch)
  const { live_movie_view_count, advertise_seen_count_change } = MovieAction


  const running_status = useSelector((state) => state.home.running_status)
  const [popupOpen, setPopupOpen] = useState(false)
  const [ip, setIp] = useState("")
  useEffect(() => {

    (async () => {
      if (location?.state) {

        setMovieData(location?.state?.movie)

        console.log("tempAdsFlag", adsFlag, movieData)
        const res = await axios.get("https://api.ipify.org/?format=json");
        const result = await change_running_status({ user: user_id, login_type: "Web", running_status: "Running", ip_address: res.data.ip, access_type: location?.state?.movie?.Data?.movie_access })
        // dispatch(change_running_status({ user: user_id, login_type: "Web", running_status: "Running", ip_address: res.data.ip }))
        setIp(res.data.ip)

      }
    })()

  }, [location?.state])

  useMemo(async () => {
    // setAdsFlag(movieData?.advertise?.map((ele) => true))
    let userObj = {
      movie: location?.state?.movie?.Data?.id,
      status: "Running"
    }
    const send_status = await live_movie_view_count(userObj)

  }, [location?.state?.movie?.Data?.id])

  useMemo(async () => {
    setAdsFlag(location?.state?.movie?.advertise?.map((ele) => true) || [])


  }, [movieData])


  const videoJsOptions = {
    autoplay: true,
    loop: false,
    controls: true,
    responsive: true,
    fluid: true,
    muted: false,
    title: movieData?.Data?.movie_name,
    textTrackSettings: false,
    // playbackRates: [0.5, 1, 1.5, 2],
    userActions: { hotkeys: true },
    controlBar: {
      qualitySelector: true,
      fullscreenToggle: true,
      volumePanel: { inline: false },
      pictureInPictureToggle: false,
      skipButtons: {
        forward: 10,
        backward: 10
      }

    },


    tracks: movieData?.subtitle?.map((ele) => ({ src: ele?.subtitle_file, kind: 'subtitles', srclang: 'en', label: ele?.subtitle_language })),
    audio: movieData?.audio?.map((ele) => ({
      id: ele?.id,
      kind: 'alternative',
      label: ele?.audio_language,
      language: ele?.audio_language,
      audio: ele?.audio_file
    })),

    // tracks: [{ src: '', kind: 'captions', srclang: 'en', label: 'English' }],
    sources: [
      {
        src: `${movieData?.Data?.movieLink_1080p}`,
        type: 'application/x-mpegURL',
      }
    ]

  };
  const handleAdvertiseSeen = (index) => {
    dispatch(advertise_seen_count_change({ advertise: location?.state?.movie?.advertise[index]?.advertise?.id, category_type: location?.state?.movie?.Data?.category_type, movie_id: location?.state?.movie?.Data?.id }))

  }

  const handleChangeMovie = async () => {
    const nextMovie = document.getElementsByClassName('recomendedMovies-player')[0];

    if (nextMovie) {
      nextMovie.remove();
    }
    let obj = {
      movie: movieData?.Data?.id,
      status: "Stop"
    }

    const send_status = await live_movie_view_count(obj)
    const res = await axios.get("https://api.ipify.org/?format=json");
    let userObj = {
      user_id: user_id,
      profile_id: profile_id,
      ip_address: res.data.ip

    }
    playerRef.current = undefined
    const resData = await getMovieDetails(movieData?.Related_Movie[0]?.slug, userObj)

    if (resData) {
      setMovieData(resData)
      setAdsFlag(resData?.advertise?.map((ele) => true) || [])

    }

    history.push(ROUTES.video, { movie: resData, resume: "" })

  }
  const handlePlayerReady = (player) => {
    var currentTime = 0;
    playerRef.current = player;

    if (location?.state?.resume) {
      const [hours, minutes, seconds] = location?.state?.resume?.split(':');
      const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);
      console.log("time", totalSeconds)

      setTimeout(() => {


        currentTime = totalSeconds
        player?.currentTime(totalSeconds)
        playerRef.current.currentTime(totalSeconds)
      }, 1000);
    }


    console.log("adsFlag", adsFlag, movieData, player.qualityLevels())

    player.controlBar.addChild('MenuButton', {
      className: 'audio_subtitle_resolution_controls',
      clickHandler: function () {
        if (!player.paused()) {
          player.pause()
        }

      }

    })

    player?.addChild("QualitySelector")
    //  player.addChild("PlaybackRateMenuButton")
    player.addChild("fullscreenToggle")
    player.controlBar.removeChild("QualitySelector")
    //  player.controlBar.removeChild("PlaybackRateMenuButton")
    player.controlBar.removeChild("fullscreenToggle")
    player.titleBar.removeClass('vjs-hidden')
    // player.titleBar.addChild('button', {
    //   controlText: `${movieData?.Data?.movie_name}`,
    //   className: 'vjs-visible-text',
    //   position: 2,
    // });
    player.markers({
      markerStyle: {
        'position': 'absolute',
        'height': '4px',
        'width': '4px',
        'top': "0",
        'background-color': 'yellow'
      },
      markers: (adsFlag || [])?.map((ele, index) => {
        var time = movieData.advertise[index]?.time?.split(":")
        time = (Number(time[0]) * 3600) + (Number(time[1]) * 60) + (Number(time[2]))
        return { time: time }
      }

      )

    })

    player.addChild('button', {
      className: 'ads-icon'
    });
    player.addChild('button', {
      className: 'go-back',
      clickHandler: function (event) {
        history.push(`${ROUTES.moviedetails}/${movieData?.Data?.slug}`)
      }
    });


    player.on("userinactive", function () {
      document.getElementsByClassName("vjs-fullscreen-control")[0].style.display = "none"
      document.getElementsByClassName(" go-back")[0].style.display = "none"



    })
    player.on("useractive", function () {
      document.getElementsByClassName("vjs-fullscreen-control")[0].style.display = "block"
      document.getElementsByClassName(" go-back")[0].style.display = "block"
    })

    player.ads();

    const [hours, minutes, seconds] = movieData.Data?.skip_start?.split(':') || [0, 0, 0];
    const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);

    const [hours1, minutes1, seconds1] = movieData.Data?.skip_end?.split(':') || [0, 0, 0];
    const endSkip = (+hours1) * 60 * 60 + (+minutes1) * 60 + (+seconds1);
    let skipFlag = true

    const [hours2, minutes2, seconds2] = movieData?.Data?.movie_duration.split(':') || [0, 0, 0];
    const episodeDuration = (+hours2) * 60 * 60 + (+minutes2) * 60 + (+seconds2);

    player.on('timeupdate', function () {

      if (parseInt(player?.currentTime()) >= totalSeconds && parseInt(player?.currentTime()) <= endSkip && skipFlag) {
        player.addChild('button', {
          className: 'skip-intro',
          id: 'skip-intoduction',
          clickHandler: function (event) {
            player?.currentTime(endSkip)
          }
        })
        skipFlag = false
      }
      else if ((parseInt(player?.currentTime()) < totalSeconds || parseInt(player?.currentTime()) > endSkip) && !skipFlag) {
        // const skipButton = player.getChild('button.skip-intro');
        const skipButton = document.getElementsByClassName('skip-intro')[0];

        if (skipButton) {
          skipButton.remove();
          skipFlag = true
        }


      }

      // if (parseInt(player?.currentTime()) >= (episodeDuration - 10)) {
      //   const thumbnailButton = document.createElement('div');
      //   thumbnailButton.className = 'recomendedMovies-player';
      //   thumbnailButton.style.backgroundImage = `url(${IMAGE_ENDPOINT}/${movieData.Related_Movie[0]?.movie_poster})`;
      //   // thumbnailButton.addEventListener('click', () =>
      //   //   handleChangeMovie()
      //   // );

      //   player.addChild('button', {
      //     el: thumbnailButton,
      //     clickHandler: function (event) {
      //       handleChangeMovie()
      //       const nextMovie = document.getElementsByClassName('recomendedMovies-player')[0];

      //       if (nextMovie) {
      //         nextMovie.remove();
      //       }
      //     }
      //   });
      // }
      else {
        const nextMovie = document.getElementsByClassName('recomendedMovies-player')[0];

        if (nextMovie) {
          nextMovie.remove();
        }
      }

      adsFlag?.map((ele, index) => {
        var time = movieData.advertise[index]?.time?.split(":")
        time = (Number(time[0]) * 3600) + (Number(time[1]) * 60) + (Number(time[2]))
        if (Math.round(player?.currentTime()) >= time && ele) {

          player.trigger('adsready');
          player.controlBar.progressControl.disable()

          player.controlBar.skipForward.addClass("vjs-hidden")
          player.controlBar.skipBackward.addClass("vjs-hidden")
          document.getElementsByClassName("vjs-play-progress")[0].style.backgroundColor = "yellow"
          document.getElementsByClassName("ads-icon")[0].style.display = "block"
          var tiles = document.getElementsByClassName("vjs-marker")
          for (var i = 0; i < tiles.length; i++) {
            tiles[i].style.backgroundColor = "transparent";

          }
        }
      })

    });

    player.on('readyforpreroll', function () {
      player.ads.startLinearAdMode();
      // play your linear ad content
      // in this example, we use a static mp4
      for (let index = 0; index < adsFlag.length; index++) {
        const element = adsFlag[index];
        if (element) {
          player.src(movieData.advertise[index]?.advertise?.advertise_url);
          handleAdvertiseSeen(index)

          break
        }

      }

      player.options.controls = false;
      // send event when ad is playing to remove loading spinner
      player.on('adplaying', function () {
        player.trigger('ads-ad-started');
      });

      // resume content when all your linear ads have finished
      player.on('adended', function () {
        player.ads.endLinearAdMode();
        for (let index = 0; index < adsFlag.length; index++) {
          const element = adsFlag[index];
          if (element) {
            const temp = adsFlag
            temp[index] = false
            setAdsFlag([...temp])
            break
          }

        }
        player.controlBar.progressControl.enable()
        player.controlBar.skipForward.removeClass("vjs-hidden")
        player.controlBar.skipBackward.removeClass("vjs-hidden")
        document.getElementsByClassName("vjs-play-progress")[0].style.backgroundColor = "red"
        document.getElementsByClassName("ads-icon")[0].style.display = "none"
        // player.src(videoJsOptions.sources)

        var tiles = document.getElementsByClassName("vjs-marker")
        for (var i = 0; i < tiles.length; i++) {
          tiles[i].style.backgroundColor = "yellow";

        }

      });
    });
    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    window.addEventListener('beforeunload', async (event) => {
      event.preventDefault();
      // if (isLoggedIn && profile_id && user_id && playerRef && running_status?.statuscode == 200) {
      if (isLoggedIn && profile_id && user_id && playerRef) {

        let time = new Date(parseInt(playerRef?.current?.currentTime()) * 1000)
          .toISOString()
          .slice(11, 19);
        let userObj = {
          user: user_id,
          profile: profile_id,
          select_type: movieData?.Data?.category_type,
          movie: movieData?.Data?.id,
          serie: "",
          episode: "",
          last_watching_time: time
        }
        event.returnValue = await addContinueWatching(userObj);

      }
    });

    player.on('dispose', async () => {
      // let currentTime = player.currentTime();
      let time = new Date(parseInt(player?.currentTime()) * 1000)
        .toISOString()
        .slice(11, 19);
      videojs.log('player will dispose', time);
      if (isLoggedIn && profile_id && user_id) {
        let userObj = {
          user: user_id,
          profile: profile_id,
          select_type: movieData?.Data?.category_type,
          movie: movieData?.Data?.id,
          serie: "",
          episode: "",
          last_watching_time: time
        }
        const resData = await addContinueWatching(userObj)
        const res = await axios.get("https://api.ipify.org/?format=json");
        const status = await change_running_status({ user: user_id, login_type: "Web", running_status: "Stop", ip_address: res.data.ip })

        // let obj = {
        //   movie: movieData?.Data?.id,
        //   status: "Stop"
        // }

        // const send_status = await live_movie_view_count(obj)

      }
    });
    player.on("seeking", () => {

      currentTime = player?.currentTime()
    })

    // player.on('ended', function () {
    //   const thumbnailButton = document.createElement('div');
    //   thumbnailButton.className = 'recomendedMovies-player';
    //   thumbnailButton.style.backgroundImage = `url(http://122.170.109.73:5554/OTT/static/Promotion/bhediya-16x9-1687776267402.jpg)`;
    //   thumbnailButton.addEventListener('click', () => {

    //   });

    //   player.addChild('button', {
    //     el: thumbnailButton,
    //   });

    // });

    player.on('canplay', () => {
      if (player?.currentTime() != currentTime)
        player?.currentTime(currentTime)

    })
    player.on("loadeddata", function () {


      let QualitySelector = document.getElementsByClassName("vjs-quality-selector")[0]

      let AudioButton = document.getElementsByClassName("vjs-audio-button")[0]

      QualitySelector.classList.remove("vjs-hidden")
      QualitySelector.getElementsByClassName("vjs-menu")[0].classList.remove("vjs-hidden")
      QualitySelector.getElementsByClassName("vjs-menu")[0].classList.add("always-open")

      AudioButton.classList.remove("vjs-hidden")
      AudioButton.getElementsByClassName("vjs-menu")[0].classList.remove("vjs-hidden")
      AudioButton.getElementsByClassName("vjs-menu")[0].classList.add("always-open")

      try {
        const li1 = document.createElement("li");
        li1.setAttribute("class", " vjs-menu-item vjs-alternative-menu-item custom-li");
        li1.appendChild(QualitySelector);

        document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("ul")[0].appendChild(li1)
      }
      catch (error) {
        console.log(error, "li1")
      }
      try {
        const li2 = document.createElement("li");
        li2.setAttribute("class", " vjs-menu-item vjs-alternative-menu-item custom-li li-second");
        li2.appendChild(AudioButton);
        document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("ul")[0].appendChild(li2)
      }
      catch (error) {
        console.log(error, "li2")
      }

      let SubsCapsButton = document.getElementsByClassName("vjs-subs-caps-button")[0]
      SubsCapsButton.classList.remove("vjs-hidden")
      SubsCapsButton.getElementsByClassName("vjs-menu")[0].classList.remove("vjs-hidden")
      SubsCapsButton.getElementsByClassName("vjs-menu")[0].classList.add("always-open")
      try {
        const li3 = document.createElement("li");
        li3.setAttribute("class", " vjs-menu-item vjs-alternative-menu-item custom-li");
        li3.appendChild(SubsCapsButton);

        document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("ul")[0].appendChild(li3)
      }
      catch (error) {
        console.log(error, "li3")
      }

      var qualitySelectorItems = document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("ul");
      for (var i = 0; i < qualitySelectorItems.length; i++) {

        qualitySelectorItems[i].addEventListener("click", function (event) {
          event.stopPropagation()
          document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("button")[0].click()

        });

      }
      var lis = document.getElementsByClassName("custom-li")
      for (var i = 0; i < lis.length; i++) {

        if (lis[i].children.length <= 0) {

          lis[i].remove()
        }
      }
      var lis = document.getElementsByClassName("li-second")
      for (var i = 0; i < lis.length; i++) {

        if (lis[i].children.length <= 0) {

          lis[i].remove()
        }
      }



    })




    player.on("qualityRequested", () => {
      if (player.play()) {
        player.pause()
      }
      const temp = document.getElementsByClassName("vjs-menu-item vjs-selected")[3].getElementsByClassName("vjs-menu-item-text")[0].innerText
      player.src(videoJsOptions.sources.find((ele) => ele?.label == temp))
      for (let i = 0; i < videoJsOptions.sources.length; i++) {
        videoJsOptions.sources[i].selected = (videoJsOptions.sources[i]?.label == temp)
      }
      currentTime = player?.currentTime()
      player.load();

    })


  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    history.goBack()
    dispatch({ type: RUNNING_STATUS, payload: null })
  };

  useMemo(() => {
    if (running_status?.statuscode == 406) {
      setPopupOpen(true)



    }
  }, [running_status])

  return (
    <>
      <Modal
        open={popupOpen}
        onClose={handlePopupClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style}>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={logo} width={"100px"} />
          </div>
          <hr />

          <div className="login-popup" >
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
              {running_status?.message}
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-around" }}>

              <button onClick={handlePopupClose} className={"btn btn-hover iq-button"} style={{ display: "block", margin: "0 auto" }}>
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      {!popupOpen && <div className='video-parent video'>
        <VideoJS playerRef={playerRef} options={videoJsOptions} onReady={handlePlayerReady} />
      </div>}
      {/* <div onClick={() => history.goBack()} className='go-back' ><img src={left_arrow} width={"40px"}/></div> */}

    </>
  )
}


