import React, { useEffect, useState, useRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Checkout from './Checkout';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { CircularProgress } from '@mui/material';

export default function StripePayment() {
  const temp = useRef()
  const location = useLocation()
  const [clientSecret, setClientSecret] = useState("");
  const [userData, setUserData] = useState(location?.state?.paymentData)

  const stripePromise = loadStripe('pk_test_SIbdw5wMvHrKxtzKsBmf2GhO00kCblC8MF');

  const stripe = require('stripe')('sk_test_LfW4Fw7PFIPuDd5m1k1aZSgw00pvixLEJH');


  useEffect(() => {
    (async () => {
      const customer = await stripe.customers.create({
        email: userData?.email,
        name: userData?.name,
        // address: {
        //   line1: 'Westgate Business Bay',
        //   city: 'Ahmedabad',
        //   state: 'Gujarat',
        //   postal_code: '12345',
        //   country: 'IND',
        // },

      });

      const paymentIntent = await stripe.paymentIntents.create({
        amount: (userData.amount) * 100,
        currency: 'usd',
        customer: customer.id,
        metadata: {
          adfree: userData?.adfree || "false",
          slug: userData?.slug || null,
          category_type: userData?.category_type || null,
          email: userData?.email,
          mobile_no: userData?.mobile,
          movie: userData?.movie || null,
          name: userData?.name,
          plan: userData?.plan,
          promocode: userData?.promocode || null,
          series: userData?.series || null,
          transaction_type: userData?.transaction_type || null
        },
        automatic_payment_methods: {
          enabled: true,
        },
      });
      setClientSecret(paymentIntent?.client_secret)

    })();
  }, [])
  const appearance = {
    theme: 'night',
    labels: "floating",
    // variables: {
    //   fontFamily: 'Sohne, system-ui, sans-serif',
    //   fontWeightNormal: '500',
    //   borderRadius: '8px',
    //   colorBackground: '#000000',
    //   colorPrimary: '#cb1039',
    //   accessibleColorOnColorPrimary: '#1A1B25',
    //   colorText: 'white',
    //   colorTextSecondary: 'white',
    //   colorTextPlaceholder: '#727F96',
    //   tabIconColor: 'white',
    //   logoColor: 'dark'
    // },
    // rules: {
    //   '.Input, .Block': {
    //     backgroundColor: 'transparent',
    //     border: '1.5px solid var(--colorPrimary)'
    //   }
    // }
  };
  console.log("paymentIntent", clientSecret, stripePromise, stripe)

  const options = {
    clientSecret: clientSecret,
    appearance

  };
  useMemo(() => {
    console.log("style", temp.current, options.clientSecret)

  }, [options.clientSecret])
  return (
    <div >
      {clientSecret ? (
        <div ref={temp}>
          <Elements stripe={stripePromise} options={options}>
            <Checkout />
          </Elements>
        </div>
      ) :
        <div style={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center" }}>
          <CircularProgress />
        </div>}
    </div>
  );
};

