import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form, Dropdown, DropdownButton } from 'react-bootstrap'

import { Link, useHistory } from 'react-router-dom'
import "./form.css"
// rtl 
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import { complaint_create, user_details_web } from "../../../../actions/HomeActions"
import { useSelector } from 'react-redux';
import bg from "../../../../assets/images/signup_background.jpg"
import Alert from 'react-bootstrap/Alert';
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import { toast } from 'react-toastify';

const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
const Complaint = (props) => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const dispatch = useDispatch()
    let history = useHistory()
    const [show, setShow] = useState(false);
    const [messageTimeout, setMessageTimeout] = useState(false)
    const profile = JSON.parse(sessionStorage.getItem('profile'))
    const user_details = useSelector((state) => state.home.user_details?.data)
    const [selectedValue, setSelectedValue] = useState("Select Complaint Type")
    const [image, setImage] = useState("")


    useEffect(() => {
        dispatch(user_details_web({ user_id: user?.id }))

        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.rtlModeAction(props.rtlMode)
        }
        else {
            props.rtlModeAction(rtlMode);
        }
    }, [])




    const handleComplaintSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        data.append("name", user?.firstName)
        data.append("user", user?.id)
        data.append("email", user?.email)
        data.append("mobileNumber", user?.mobileNumber)
        data.append("complaint_type", selectedValue)
        data.append("image", image)

        if (selectedValue == "Select Complaint Type") {
            toast.error("Please select complaint type")
        }
        else {
            dispatch(complaint_create(data));

        }
        //   console.log(data.get("image"))
    }
    const complaint_message = useSelector((state) => state.home.complaint_message)
    useMemo(() => {

        setMessageTimeout(true)

        setTimeout(() => { setMessageTimeout(false) }, 2000)
    }, [complaint_message])

    const handleValue = (e) => {
        console.log(e.target.value)
        setSelectedValue(e.target.value)
    }
    return (
        <>

            {/* <section className="sign-in-page" style={{backgroundImage:"url("+bg+")"}}> */}
            <section className="sign-in-page" style={{ marginTop: "5rem" }}>
                <Container >
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="7" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        <Form onSubmit={(event) => handleComplaintSubmit(event)}>

                                            <p style={{ textAlign: "center", marginBottom: "1rem", fontWeight: "400", fontSize: "24px" }}>Help & Support</p>
                                            <Row >

                                                <Col md="12" >
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div style={{ display: "flex" }}>
                                                            <div style={{ marginRight: "1rem" }}>
                                                                <img src={IMAGE_ENDPOINT + profile?.avatar?.avatarImage} style={{ maxWidth: "75px" }} className="rounded img-fluid d-block mx-auto mb-3" alt="user" />

                                                            </div>
                                                            <div>
                                                                <p style={{ marginBottom: "0rem" }}>User Name   : <span style={{ color: "white" }}>{user_details?.firstName + " " + user_details?.lastName}</span></p>
                                                                <p style={{ marginBottom: "0rem" }}>Profile Name   : <span style={{ color: "white" }}>{profile?.profileName}</span></p>
                                                                <p style={{ marginBottom: "0rem" }}>E-mail : <span style={{ color: "white" }}>{user_details?.email}</span></p>
                                                                <p style={{ marginBottom: "1rem" }}>Mobile : <span style={{ color: "white" }}>{user_details?.mobileNumber}</span></p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="6" >
                                                    <Form.Group className='btn-change drpdwn-colour'>
                                                        <Form.Label style={{ display: "flex", color: "white" }}> Select Complaint type</Form.Label>
                                                        <DropdownButton id="dropdown-item-button" className='mt-2' title={selectedValue}>
                                                            <Dropdown.Item as="option" style={{ cursor: "pointer", color: "white" }} value="Player not working" onClick={(e) => handleValue(e)}>Player not working</Dropdown.Item>
                                                            <Dropdown.Item as="option" style={{ cursor: "pointer", color: "white"  }} value="Subscription not working" onClick={(e) => handleValue(e)}>Subscription not working</Dropdown.Item>
                                                            <Dropdown.Item as="option" style={{ cursor: "pointer", color: "white"  }} value="Content is not appropriate" onClick={(e) => handleValue(e)}>Content is not appropriate</Dropdown.Item>
                                                            <Dropdown.Item as="option" style={{ cursor: "pointer", color: "white"  }} value="Profile related issue" onClick={(e) => handleValue(e)}>Profile related issue</Dropdown.Item>
                                                            <Dropdown.Item as="option" style={{ cursor: "pointer", color: "white"  }} value="Application related issue" onClick={(e) => handleValue(e)}>Application related issue</Dropdown.Item>
                                                            <Dropdown.Item as="option" style={{ cursor: "pointer", color: "white"  }} value="Other" onClick={(e) => handleValue(e)}>Other</Dropdown.Item>
                                                        </DropdownButton>
                                                        {/* <Form.Control.Feedback  type="invalid" style={{textAlign:"center"}}>
              Please enter the Complaint name.
            </Form.Control.Feedback> */}
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6" >
                                                    <Form.Group controlId="formFile" className="mb-3">
                                                        {/* <Form.Label style={{ display: "flex", color: "white" }}> Image</Form.Label>

                                                        <Form.Control type="file" name='image' className="file mt-2" ></Form.Control> */}


                                                        <div id="file-upload-form" className="uploader">
                                                            <input id="file-upload" type="file" name="fileUpload" accept="image/*" onChange={(e) => setImage(e.target.files[0])} />

                                                            <label for="file-upload" id="file-drag">
                                                                <img id="file-image" src="#" alt="Preview" className="hidden" />
                                                                <div id="start">
                                                                    {/* <i className="fa fa-download" aria-hidden="true"></i> */}
                                                                    <div className='mt-3'>Select an image or drag here</div>
                                                                    {/* <div id="notimage" className="hidden">Please select an image</div> */}
                                                                    <span id="file-upload-btn" className="btn btn-primary">Select Image{image?.target?.value}</span><br />
                                                                    <span id="file-upload-btn" className="">{image?.name}</span>

                                                                </div>
                                                                <div id="response" className="hidden">
                                                                    <div id="messages"></div>
                                                                    <progress className="progress" id="file-progress" value="0">
                                                                        <span>0</span>%
                                                                    </progress>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md="12" >
                                                    <Form.Group style={{ width: "100%" }}>
                                                        <Form.Label style={{ display: "flex", color: "white" }}> Description</Form.Label>

                                                        <Form.Control as="textarea" name='description' id="exampleInputEmail2" required></Form.Control>

                                                        {/* <Form.Control.Feedback  type="invalid" style={{textAlign:"center"}}>
              Please enter the Complaint name.
            </Form.Control.Feedback> */}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {complaint_message?.message && messageTimeout && (complaint_message?.statuscode == 200 ?
                                                <Alert variant="success" style={{ background: "rgb(0,255,0,0.1)", borderColor: "rgb(0,255,0)" }} >
                                                    <p style={{ color: "rgb(0,255,0)", fontWeight: "500", fontSize: "14px" }}>
                                                        {complaint_message?.message}
                                                    </p>
                                                </Alert>
                                                : <Alert variant="danger" style={{ background: "rgb(255,0,0,0.1)", borderColor: "rgb(255,0,0)" }} >
                                                    <p style={{ color: "rgb(255,0,0)", fontWeight: "500", fontSize: "14px" }}>
                                                        {complaint_message?.message}
                                                    </p>
                                                </Alert>)}
                                            <Button type='submit' style={{ display: "block", margin: "0 auto" }} className="btn btn-hover" >Submit</Button>

                                        </Form>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Complaint)