import { Switch, Route } from 'react-router-dom'

import SignUp from '../views/backend/pages/auth/signup'
import Login from '../views/backend/pages/auth/signin'
import Video from '../views/backend/pages/video'
import RecoverPswd from '../views/backend/pages/auth/recover-pswd'
import profile_selection from '../views/backend/pages/auth/profile_selection';
import profile_manage from '../views/backend/pages/auth/profile_manage';
import profile_pin from '../views/backend/pages/auth/profile_pin'

const ExtraPages = () => {
    return (
        <Switch>
            <Route path="/auth/watch-video" component={Video} />
            <Route path="/auth/login" component={Login} />
            <Route path="/auth/recover-pswd" component={RecoverPswd} />
            <Route path="/auth/profile_selection" component={profile_selection} />
            <Route path="/auth/profile_manage" component={profile_manage} />
            <Route path="/auth/profile_pin" component={profile_pin} />

            

        </Switch>
    )
}

export default ExtraPages