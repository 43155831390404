import React, { useState } from 'react'
import { Container, Col, Row, Nav, Tab, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

import trendinglabel from '../../../assets/images/trending/trending-label.png'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../constant/routes';
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";


export default function Genres(props) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const history = useHistory()
    const options1 = [
        { value: 'season 1', label: 'Season 1' },
        { value: 'season 2', label: 'Season 2' },
        { value: 'season 3', label: 'Season 3' }
    ]

    const options2 = [
        { value: 'season 1', label: 'Season 1' },
        { value: 'season 2', label: 'Season 2' }
    ]

    const handleGenreId = (id) => {
        history.push(ROUTES.moviesByGenre, { id: id })
    }

    return (
        <div className='main-content'>
            <section id="iq-favorites">
                <Container fluid>
                    <Row>
                        <Col sm="12" className="overflow-hidden">
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 style={{ textTransform: "uppercase" }} className="main-title">{props?.genres?.Name}</h6>
                                {/* <Link className="iq-view-all" to="/movie-category">View All</Link> */}
                            </div>
                            <div id="favorites-contens">
                                {/* <div id="prev5" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                <div id="next5" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                <Swiper
                                    navigation={{
                                        prevEl: '#prev5',
                                        nextEl: '#next5'
                                    }}
                                    breakpoints={{
                                        320: { slidesPerView: 2 },
                                        450: { slidesPerView: 3 },
                                        638: { slidesPerView: 4 },
                                        991: { slidesPerView: 5 },
                                        1300: { slidesPerView: 6 }

                                    }}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    autoplay={{
                                        delay: 1000,
                                    }}
                                    // loop={(movies?.length > 4) ? true : false}
                                    loop={true}
                                    slidesPerView={6}
                                    spaceBetween={0}
                                    as="ul"
                                    className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">
                                    {

                                        props?.genres?.data?.map((data, index) => (
                                            <SwiperSlide as="li" key={index}>
                                                <div onClick={() => handleGenreId(data?.id)}>
                                                    <img src={`${IMAGE_ENDPOINT}/${data?.genre_image}`} className="language-img genre-border" alt={data?.genre_title} />
                                                    {/* <p className='mt-2' style={{ textAlign: "center" }}>{data?.genre_title}</p> */}
                                                </div>
                                            </SwiperSlide>
                                        ))

                                    }

                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
